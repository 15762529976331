import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';

import './fileBlock.scss';
import GuideContext from '../../../stores/guideContext';
import Input from '../../formElements/input/input';
import AuthContext from '../../../stores/authContext';
import BlockActions from '../components/blockActions/blockActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function FileBlock(props) {
    const { pageContent, setPageContent, drag, dragEnd, removeBlock } = useContext(GuideContext)
    const { user } = useContext(AuthContext)
    const [label, setLabel] = useState('')
    const [fileName, setFileName] = useState('')
    const [error, setError] = useState('')
    const [draggable, setDraggable] = useState(false)
    const [edit, setEdit] = useState(false)
    const [count, setCount] = useState('')

    useEffect(() => {
        if (props.initialValue) {
            setLabel(props.initialValue.label)
            setFileName(props.initialValue.fileName)
        }
    }, [props.initialValue])

    useEffect(() => {
        props.showEdit && setEdit(true)
    }, [props.showEdit])

    useEffect(() => {
        if (fileName) {
            setPageContent(
                prevState => [
                    ...prevState.slice(0, prevState.findIndex(i => i.id === props.id)), 
                    Object.assign({}, prevState[prevState.findIndex(i => i.id === props.id)], { value: `{"fileName":"${fileName}", "label":"${label ? label : fileName}"}`, type: 'file-block', parent: props.parent }), 
                    ...prevState.slice(prevState.findIndex(i => i.id === props.id) + 1)
                ]
            )
        }
    }, [label, fileName, props.id, props.parent, setPageContent])

    useEffect(() => {
        const index = pageContent.filter(i => i.type === 'file-block').findIndex(i => i.id === props.id)
        !count && setCount(index + 1)
    }, [props.id, pageContent, count])

    const handleChange = (e) => {
        if (e.target.files.length) {
            const fd = new FormData()
            fd.append(e.target.name, e.target.files[0], e.target.files[0].name)
            axios.post(`${process.env.REACT_APP_API_PATH}/api/files/upload`, fd, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                if (res.data.error) {
                    setError(res.data.error)
                } else {
                    setFileName(res.data)
                    setPageContent(
                        prevState => [
                            ...prevState.slice(0, prevState.findIndex(i => i.id === props.id)), 
                            Object.assign({}, prevState[prevState.findIndex(i => i.id === props.id)], {value: `{"fileName":"${res.data}", "label":"${label ? label : res.data}"}`, type: 'file'}), 
                            ...prevState.slice(prevState.findIndex(i => i.id === props.id) + 1)
                        ]
                    )
                }
            })
        }
    }

    return (
        <div 
            className = 'FileBlock'
            draggable = { draggable }
            id = { props.id }
            onDrag = { (e) => drag(e) }
            onDragEnd = { () => dragEnd() }
        >
            <div>
                <BlockActions 
                    id = { props.id } 
                    parent = { props.parent } 
                    edit = { true } 
                    editAction = { () => setEdit(true) }
                    name = { 'Tiedosto ' + count }
                />
                <FontAwesomeIcon 
                    onMouseOver = { () => !draggable ? setDraggable(true) : null } 
                    onMouseLeave = { () => draggable ? setDraggable(false) : null }
                    icon = 'arrows-alt' 
                />
            </div>
            {label ?
                <button className='btn btn-primary'><label htmlFor={ 'edit_' + props.id }>{ label }</label></button>
            :
                <button className='btn btn-primary'><label htmlFor={ 'edit_' + props.id }>{ fileName ? fileName : 'Valitse tiedosto' }</label></button>
            }
            { edit &&
                <React.Fragment>
                    <div onClick={ () => setEdit(false) } className='Backdrop'></div>
                    <div className='Popup'>
                        <button className='Close' onClick={ () => setEdit(false) }>Sulje</button>
                        <Input 
                            inputtype = 'text'
                            name = 'label'
                            title = 'Otsikko'
                            value = { label }
                            placeholder = 'Otsikko'
                            handlechange = { (e) => setLabel(e.target.value)}
                        />
                        <button className='btn btn-primary'><label htmlFor={ 'upload_' + props.id }>Valitse tiedosto</label></button>
                        <p><strong>Valittu tiedosto:</strong> { fileName }</p>
                        { error &&
                            <p><strong>{ error }</strong></p>
                        }
                        <input 
                            type = 'file'
                            name = 'file'
                            className = 'UploadButton' 
                            id = { 'upload_' + props.id }
                            placeholder = { fileName }
                            onChange = { handleChange }
                        />
                        <button className='btn btn-primary' onClick={ () => setEdit(false) }>Lisää</button>
                        <button className='btn btn-danger' onClick={ () => removeBlock(props.id) }>Poista</button>
                    </div>
                </React.Fragment>
            }
            <input 
                name = 'file'
                className = 'UploadButton' 
                id = { 'edit_' + props.id } 
                onClick = { () => setEdit(true) }
            />
        </div>
    )
}

export default FileBlock