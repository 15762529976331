import { useContext, useState, useEffect } from 'react';
import axios from 'axios';

import Layout from '../../components/UI/layout/layout';
import AuthContext from '../../stores/authContext';
import Input from '../../components/formElements/input/input';
import Button from '../../components/formElements/button/button';
import Message from '../../components/message/message';
import SettingsValidation from '../../validation/settingsValidation';

function EditSettings(props) {
    const { message, setMessage, user } = useContext(AuthContext)
    const [price, setPrice] = useState('')
    const [email, setEmail] = useState('')
    const [invalidFields, setInvalidFields] = useState([])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_PATH}/api/settings`).then(res => {
            setPrice(res.data.hourly_price)
            setEmail(res.data.email)
        })
    }, [])

    function handleFormValidation(e) {
        e.preventDefault()
        const validation = SettingsValidation(price, email)
        let message = []
        let invalid = []
        Object.entries(validation).forEach(([key, value]) => {
            if (!value.condition) {
                message.push(<li key={ key }>{ value.message }</li>)
                invalid.push(key)
            }
        })

        if (message.length > 0) {
            setInvalidFields(invalid)
            setMessage({ message: message, class: 'Error' })
            window.scrollTo({ top: 0, behavior: "smooth" })
        } else {
            handleFormSubmit()
        }
    }

    function handleFormSubmit() {
        const data = {
            hourly_price: price,
            email: email
        }

        axios.patch(`${process.env.REACT_APP_API_PATH}/api/settings/${1}`, data, {
            headers: {
                'auth-token': user.token
            }
        }).then(() => {
            setMessage({ message: 'Asetusten muokkaaminen onnistui.', class: 'Success' })
            window.scrollTo({ top: 0, behavior: "smooth" })
        }).catch(() => {
            setMessage({ message: 'Jotain meni pieleen.', class: 'Error' })
            window.scrollTo({ top: 0, behavior: "smooth" })
        })
    }

    return (
        <Layout>
            { message &&
                <Message 
                    classes = { message.class }
                    message = { message.message }
                    closeMessage = { () => setMessage(null) }
                />
            }
            <h1>Muokkaa asetuksia</h1>
            <form onSubmit={ handleFormValidation }>
                <Input 
                    inputtype = 'text'
                    name = 'price'
                    title = 'Tuntihinta'
                    value = { price }
                    placeholder = 'Tuntihinta'
                    handlechange = { (e) => setPrice(e.target.value) }
                    invalidFields = { invalidFields }
                />
                <Input 
                    inputtype = 'text'
                    name = 'email'
                    title = 'Sähköpostiosoite'
                    value = { email }
                    placeholder = 'Sähköpostiosoite'
                    handlechange = { (e) => setEmail(e.target.value) }
                    invalidFields = { invalidFields }
                />
                <Button 
                    action = { handleFormValidation }
                    type = 'btn btn-primary'
                    title = 'Tallenna'
                />
            </form>
        </Layout>
    )
}

export default EditSettings