import { useContext, useState, useEffect } from 'react';
import axios from 'axios';

import Layout from '../../components/UI/layout/layout';
import AuthContext from '../../stores/authContext';
import Message from '../../components/message/message';
import ProductCategoryValidation from '../../validation/productCategoryValidation';
import Input from '../../components/formElements/input/input';
import Button from '../../components/formElements/button/button';
import Wrapper from '../../components/UI/wrapper/wrapper';
import Checkbox from '../../components/formElements/checkbox/checkbox';

function CreateProductCategories(props) {
    const { message, setMessage, user } = useContext(AuthContext)
    const [name, setName] = useState('')
    const [machineName, setMachineName] = useState('')
    const [responsibilities, setResponsibilities] = useState([])
    const [selectedResponsibilities, setSelectedResponsibilities] = useState([])
    const [specialChars] = useState(/[` ~!@#$%^&*()_|+\-=?;:'",.<>{}[\]\\/]/gi)
    const [invalidFields, setInvalidFields] = useState([])

    function clearState() {
        setName('')
        setMachineName('')
        setSelectedResponsibilities([])
        setInvalidFields([])
    }

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_PATH}/api/responsibilities`).then(res => {
            setResponsibilities(res.data)
        })
    }, [])

    useEffect(() => {
        setMachineName(name.replace(specialChars, '_').replace(/[äå]/gi, 'a').replace(/[ö]/gi, 'o').toLocaleLowerCase())
    }, [name, specialChars])

    function handleSelectResponsibilities(responsibility) {
        if (selectedResponsibilities && selectedResponsibilities.filter(i => i.name === responsibility.name).length > 0) {
            const index = selectedResponsibilities.findIndex(i => i.name === responsibility.name)
            setSelectedResponsibilities(prevState => [...prevState.slice(0, index), ...prevState.slice(index + 1)])
        } else {
            setSelectedResponsibilities(prevState => [...prevState, responsibility])
        }
    }

    function handleFormValidation(e) {
        e.preventDefault()
        const validation = ProductCategoryValidation(name, machineName)
        let message = []
        let invalid = []
        Object.entries(validation).forEach(([key, value]) => {
            if (!value.condition) {
                message.push(<li key={ key }>{ value.message }</li>)
                invalid.push(key)
            }
        })

        if (message.length > 0) {
            setInvalidFields(invalid)
            setMessage({ message: message, class: 'Error' })
            window.scrollTo({ top: 0, behavior: "smooth" })
        } else {
            handleFormSubmit()
        }
    }

    function handleFormSubmit() {
        const data = {
            name: name,
            machine_name: machineName,
            product_category_responsibilities: selectedResponsibilities,
            position: 0
        }

        axios.post(`${process.env.REACT_APP_API_PATH}/api/products/categories`, data, {
            headers: {
                'auth-token': user.token
            }
        }).then(() => {
            clearState()
            setMessage({ message: 'Tuotekategorian lisääminen onnistui.', class: 'Success' })
            window.scrollTo({ top: 0, behavior: "smooth" })
        }).catch(() => {
            setMessage({ message: 'Jotain meni pieleen.', class: 'Error' })
            window.scrollTo({ top: 0, behavior: "smooth" })
        })
    }

    return (
        <Layout>
            { message &&
                <Message 
                    classes = { message.class }
                    message = { message.message }
                    closeMessage = { () => setMessage(null) }
                />
            }
            <h1>Lisää tuotekategoria</h1>
            <form onSubmit={ handleFormValidation }>
                <Input 
                    inputtype = 'text'
                    name = 'name'
                    title = 'Nimi'
                    value = { name }
                    placeholder = 'Kategorian nimi'
                    handlechange = { (e) => setName(e.target.value) }
                    invalidFields = { invalidFields }
                />
                <Input 
                    inputtype = 'text'
                    name = 'machineName'
                    title = 'Koneluettava nimi'
                    value = { machineName }
                    placeholder = 'Ohjeen nimi'
                    handlechange = { 
                        (e) => setMachineName(e.target.value.replace(specialChars, '_').replace(/[äå]/gi, 'a').replace(/[ö]/gi, 'o').toLocaleLowerCase()) 
                    }
                    invalidFields = { invalidFields }
                />
                { responsibilities &&
                    <Wrapper>
                        <strong><label className='WrapperLabel'>Vastuu</label></strong>
                        { responsibilities.map(item => {
                            return (
                                <Checkbox 
                                    key = { item.id }
                                    id = { 'responsibility_' + item.id }
                                    name = { item.name }
                                    action = { () => handleSelectResponsibilities(item) }
                                    checked = {
                                        selectedResponsibilities.filter(i => i.id === item.id).length > 0 ?
                                            true 
                                        : null
                                    }
                                />
                            )
                        }) }
                    </Wrapper>
                }
                <Button 
                    action = { handleFormValidation }
                    type = 'btn btn-primary'
                    title = 'Tallenna'
                />
            </form>
        </Layout>
    )
}

export default CreateProductCategories