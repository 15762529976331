import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';

import './imageBlock.scss';
import GuideContext from '../../../stores/guideContext';
import AuthContext from '../../../stores/authContext';
import BlockActions from '../components/blockActions/blockActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Input from '../../formElements/input/input';
import Textarea from '../../formElements/textarea/textarea';

function ImageBlock(props) {
    const [preview, setPreview] = useState(null)
    const { pageContent, setPageContent, drag, dragEnd, removeBlock } = useContext(GuideContext)
    const { user } = useContext(AuthContext)
    const [draggable, setDraggable] = useState(false)
    const [edit, setEdit] = useState(false)
    const [path, setPath] = useState('')
    const [altText, setAltText] = useState('')
    const [caption, setCaption] = useState('')
    const [count, setCount] = useState('')
    const [error, setError] = useState('')

    const handleChange = e => {
        if (e.target.files.length) {
            const fd = new FormData()
            fd.append(e.target.name, e.target.files[0], e.target.files[0].name)
            axios.post(`${process.env.REACT_APP_API_PATH}/api/images/upload`, fd, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                if (res.data.error) {
                    setError(res.data.error)
                } else {
                    setPreview(res.data.name)
                    setPath(res.data.name)
                    setPageContent(
                        prevState => [
                            ...prevState.slice(0, prevState.findIndex(i => i.id === props.id)),
                            Object.assign({}, prevState[prevState.findIndex(i => i.id === props.id)], { value: `{"path":"${res.data.name}", "altText":"${altText}", "caption":"${caption}"}`, type: 'image-block', parent: props.parent }),
                            ...prevState.slice(prevState.findIndex(i => i.id === props.id) + 1)
                        ]
                    )
                }
            })
        }
    }

    useEffect(() => {
        setPageContent(
            prevState => [
                ...prevState.slice(0, prevState.findIndex(i => i.id === props.id)),
                Object.assign({}, prevState[prevState.findIndex(i => i.id === props.id)], { value: `{"path":"${path}", "altText":"${altText}", "caption":"${caption}"}`, type: 'image-block', parent: props.parent }),
                ...prevState.slice(prevState.findIndex(i => i.id === props.id) + 1)
            ]
        )
    }, [path, altText, caption, props.id, props.parent, setPageContent])

    useEffect(() => {
        if (props.initialValue) {
            setPreview(props.initialValue.path)
            setPath(props.initialValue.path)
            setAltText(props.initialValue.altText)
            setCaption(props.initialValue.caption)
        }
    }, [props.initialValue])

    useEffect(() => {
        props.showEdit && setEdit(true)
    }, [props.showEdit])

    useEffect(() => {
        const index = pageContent.filter(i => i.type === 'image-block').findIndex(i => i.id === props.id)
        !count && setCount(index + 1)
    }, [props.id, pageContent, count])

    return (
        <div 
            className = 'ImageBlock'
            draggable = { draggable }
            id = { props.id }
            onDrag = { (e) => drag(e) }
            onDragEnd = { () => dragEnd() }
        >
            <div>
                <BlockActions 
                    id = { props.id } 
                    parent = { props.parent }
                    edit = { true } 
                    editAction = { () => setEdit(true) }
                    name = { 'Kuva ' + count }
                />
                <FontAwesomeIcon 
                    onMouseOver = { () => !draggable ? setDraggable(true) : null } 
                    onMouseLeave = { () => draggable ? setDraggable(false) : null }
                    icon = 'arrows-alt' 
                />
            </div>
            { edit &&
                <React.Fragment>
                    <div onClick={ () => setEdit(false) } className='Backdrop'></div>
                    <div className='Popup'>
                        <button className='Close' onClick={ () => setEdit(false) }>Sulje</button>
                        <div style={{ marginBottom: '15px' }}>
                            <input 
                                type = 'file'
                                accept = 'image/*' 
                                name = 'image'
                                id = { 'upload_' + props.id }
                                placeholder = { path }
                                onChange = { handleChange }
                            />
                        </div>
                        { error &&
                            <p><strong>{ error }</strong></p>
                        }
                        <Input 
                            inputtype = 'text'
                            name = 'altText'
                            title = 'Alt-teksti'
                            value = { altText }
                            placeholder = 'Alt-teksti'
                            handlechange = { (e) => setAltText(e.target.value)}
                        />
                        <Textarea 
                            name = 'caption'
                            title = 'Kuvateksti'
                            value = { caption }
                            rows = { 5 }
                            handlechange = { (e) => setCaption(e.target.value) }
                        />
                        <button className='btn btn-primary' onClick={ () => setEdit(false) }>Lisää</button>
                        <button className='btn btn-danger' onClick={ () => removeBlock(props.id) }>Poista</button>
                    </div>
                </React.Fragment>
            }
            { preview &&
                <img className='ImagePreview' src={ `${process.env.REACT_APP_API_PATH}/images/${preview}` } alt='preview' />
            }
            { !preview &&
                <div onClick={ () => setEdit(true) } className='ImagePlaceholder'>
                    <h2>Lisää kuva</h2>
                </div>
            }
        </div>
    )
}

export default ImageBlock