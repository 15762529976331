import { useContext, useState, useEffect } from 'react';
import Message from '../../components/message/message';
import Layout from '../../components/UI/layout/layout';
import AuthContext from '../../stores/authContext';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import Table from '../../components/UI/table/table';
import Pager from '../../components/pager/pager';
import Select from '../../components/formElements/select/select';
import Button from '../../components/formElements/button/button';

function ReportsIndex() {
    const { message, setMessage, user } = useContext(AuthContext)
    const [reports, setReports] = useState([])
    const [selectedItems, setSelectedItems] = useState([])
    const [massEditOptions] = useState([
        { id: 1, name: 'Poista' }
    ])
    const [massEdit, setMassEdit] = useState('')
    const [count, setCount] = useState(0)
    const [filter, setFilter] = useState('')
    const [firstItem, setFirstItem] = useState(0)
    const [itemsToShow] = useState(20)

    function getReports() {
        axios.get(`${process.env.REACT_APP_API_PATH}/api/fault-reports/${firstItem}/${itemsToShow}/${filter && filter.length > 0 ? filter : 0}`, {
            headers: {
                'auth-token': user.token
            }
        }).then(res => {
            setReports(res.data)
        })

        axios.get(`${process.env.REACT_APP_API_PATH}/api/fault-reports/count/${filter && filter.length > 0 ? filter : 0}`, {
            headers: {
                'auth-token': user.token
            }
        }).then(res => {
            setCount(res.data[0].item_count)
        })
    }

    useEffect(() => {
        if (user && user.token) {
            getReports()
        }
    }, [user, firstItem, itemsToShow, filter])

    useEffect(() => {
        setFirstItem(0)
    }, [filter])

    function handleItemSelection(id) {
        if (selectedItems.includes(id)) {
            setSelectedItems(selectedItems.filter(i => i !== id))
        } else {
            setSelectedItems([...selectedItems, id])
        }
    }

    function selectAllItems() {
        if (document.getElementById('SelectAllItems').checked) {
            let itemArray = []
            document.querySelectorAll('.SelectItem').forEach(checkbox => {
                checkbox.checked = true
                itemArray.push(parseInt(checkbox.value))
            })
            setSelectedItems(itemArray)
        } else {
            document.querySelectorAll('.SelectItem').forEach(checkbox => {
                checkbox.checked = false
                setSelectedItems([])
            })
        }
    }

    function unSelectAllItems() {
        document.getElementById('SelectAllItems').checked = false
        document.querySelectorAll('.SelectItem').forEach(checkbox => {
            checkbox.checked = false
        })
        setSelectedItems([])
    }

    function handleMassEdit() {
        if (selectedItems.length === 0) {
            setMessage({ class: 'Error', message: 'Valitse vikailmoitukset' })
        } else if (massEdit === 1) {
            axios.post(`${process.env.REACT_APP_API_PATH}/api/fault-reports/delete`, {
                items: selectedItems
            }, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                setMessage({ class: 'Success', message: 'Vikailmoitukset poistettu' })
                getReports()
                unSelectAllItems()
            })
        } else {
            setMessage({ class: 'Error', message: 'Valitse toiminto' })
        }
    }

    return (
        <Layout>
            { message &&
                <Message 
                    classes = { message.class }
                    message = { message.message }
                    closeMessage = { () => setMessage(null) }
                />
            }
            <h1>Vikailmoitukset</h1>
            <div className='GuideFilters'>
                <Select 
                    name = 'massEdit'
                    value = { massEdit }
                    options = { massEditOptions }
                    handlechange = { (e) => setMassEdit(parseInt(e.target.children[e.target.selectedIndex].value)) }
                    placeholder = 'Massamuokkaus'
                />
                <Button
                    action = { () => handleMassEdit() }
                    type = 'btn btn-success btn-mass-edit'
                    title = 'Suorita'
                />
                <input style={{ float: 'right', padding: '10px' }} value={ filter } placeholder='Hae...' onChange={ (e) => setFilter(e.target.value) } />
            </div>
            { reports &&
                <Table>
                    <thead>
                        <tr>
                            <th className="SelectAllCheckbox"><input id="SelectAllItems" onChange={() => selectAllItems()} type='checkbox' /></th>
                            <th>Osoite</th>
                            <th>Tilannut</th>
                            <th>Lisätty</th>
                            <th>Toiminnot</th>
                        </tr>
                    </thead>
                    <tbody>
                        { reports.map(report => {
                            return (
                                <tr key={ report.id }>
                                    <td><input className="SelectItem" onChange={ () => handleItemSelection(report.id)
                                    } type='checkbox' value={ report.id } checked={ selectedItems.includes(report.id) ? true : false } /></td>
                                    <td>{ report.address }</td>
                                    <td>{ report.name }</td> 
                                    <td>{ `${new Date(report.created_at).getDate()}.${new Date(report.created_at).getMonth() + 1}.${new Date(report.created_at).getFullYear()}` }</td>
                                    <td>
                                        <NavLink to={`/vikailmoitukset/${report.id}`}>Näytä</NavLink>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            }
            <Pager 
                firstItem = { firstItem }
                itemsToShow = { itemsToShow }
                count = { count }
                filter = { filter }
                handleSetFirstItem = { (value) => setFirstItem(value) }
            />
        </Layout>
    )
}

export default ReportsIndex