import React from 'react';

import './container.scss';

const Container = (props) => (
    <div className={ props.classes ? 'Container ' + props.classes : 'Container' }>
        { props.children }
    </div>
)

export default Container