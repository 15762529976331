import { useContext, useState, useEffect } from 'react';
import Button from '../../components/formElements/button/button';
import Message from '../../components/message/message';
import Layout from '../../components/UI/layout/layout';
import AuthContext from '../../stores/authContext';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import Table from '../../components/UI/table/table';

function UnitsIndex(props) {
    const { message, setMessage, user } = useContext(AuthContext)
    const [units, setUnits] = useState([])

    useEffect(() => {
        if (user && user.token) {
            axios.get(`${process.env.REACT_APP_API_PATH}/api/units`, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                setUnits(res.data)
            })
        }
    }, [user])

    return (
        <Layout>
            { message &&
                <Message 
                    classes = { message.class }
                    message = { message.message }
                    closeMessage = { () => setMessage(null) }
                />
            }
            <h1>Yksiköt</h1>
            <Button 
                action = { () => props.history.push('/tuotteet/yksikot/lisaa') }
                type = 'btn btn-primary'
                title = 'Lisää uusi'
            />
            { units &&
                <Table>
                    <thead>
                        <tr>
                            <th>Nimi</th>
                            <th>Lisätty</th>
                            <th>Päivitetty</th>
                            <th>Toiminnot</th>
                        </tr>
                    </thead>
                    <tbody>
                        { units.map(unit => {
                            return (
                                <tr key={ unit.id }>
                                    <td>{ unit.name }</td>
                                    <td>{ `${new Date(unit.created_at).getDate()}.${new Date(unit.created_at).getMonth() + 1}.${new Date(unit.created_at).getFullYear()}` }</td>
                                    <td>{ `${new Date(unit.updated_at).getDate()}.${new Date(unit.updated_at).getMonth() + 1}.${new Date(unit.updated_at).getFullYear()}` }</td>
                                    <td>
                                        <NavLink to={`/tuotteet/yksikot/muokkaa/${unit.id}`}>Muokkaa</NavLink>
                                        <NavLink to={`/tuotteet/yksikot/poista/${unit.id}`}>Poista</NavLink>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            }
        </Layout>
    )
}

export default UnitsIndex