import React, { useState, useEffect, useContext } from 'react';

import '../productOption.scss';
import Input from '../../formElements/input/input';
import ProductContext from '../../../stores/productContext';
import Button from '../../formElements/button/button';
import Checkbox from '../../formElements/checkbox/checkbox';
import Select from '../../formElements/select/select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function RadioOption(props) {
    const [options, setOptions] = useState([{ id: 1, name: '', price: '', multiple_price_with_amount: 0, first_unit_price: null }])
    const [name, setName] = useState('')
    const [required, setRequired] = useState(false)
    const [multipleAmount] = useState([
        { id: 0, name: 'Ei' },
        { id: 1, name: 'Kyllä' }
    ])
    const { setOptionValues, removeOption, moveOptions, moveOption, optionInputs } = useContext(ProductContext)

    useEffect(() => {
        props.options && setOptions(props.options)
        props.required && setRequired(props.required)
        props.name && setName(props.name)
    }, [props.options, props.required, props.name])

    useEffect(() => {
        setOptionValues(
            prevState => [
                ...prevState.slice(0, prevState.findIndex(i => i.id === props.id)), 
                Object.assign({}, prevState[prevState.findIndex(i => i.id === props.id)], { name: name, type: 'radio', required: required, options: options }), 
                ...prevState.slice(prevState.findIndex(i => i.id === props.id) + 1)
            ]
        )
    }, [options, required, name, props.id, setOptionValues])

    return (
        <div className='ProductOption'>
            <div className='TopBar'>
                <span className='Type'>Yksittäisvalinta</span>
                <button className='Remove' onClick={ () => removeOption(props.id) }>Poista optioryhmä</button>
                { parseInt(optionInputs[optionInputs.length - 1].key) !== props.id &&
                    <>
                        <FontAwesomeIcon onClick={ () => moveOptions(props.id, 'down') } icon='arrow-down' />
                        <button onClick={ () => moveOptions(props.id, 'down') }>Alas</button>
                    </>
                }
                { parseInt(optionInputs[0].key) !== props.id &&
                    <>
                        <button onClick={ () => moveOptions(props.id, 'up') }>Ylös</button>
                        <FontAwesomeIcon onClick={ () => moveOptions(props.id, 'up') } icon='arrow-up' />
                    </>
                }
            </div>
            <Input 
                inputtype = 'text'
                name = 'name'
                title = 'Otsikko'
                value = { name }
                placeholder = 'Otsikko'
                handlechange = { (e) => setName(e.target.value) }
            />
            <div style={{ marginBottom: '30px' }}>
                <Checkbox 
                    key = { props.id }
                    id = { props.id }
                    name = 'Pakollinen'
                    action = { () => setRequired(!required) }
                    checked = {
                        required ?
                            true 
                        : null
                    }
                />
            </div>
            { options && options.map(option => {
                return (
                    <div className='Option' key={ option.id }>
                        <div className='TopBar'>
                            <button className='Remove' onClick={ () => setOptions(prevState => [...prevState.slice(0, options.findIndex(i => parseInt(i.id) === parseInt(option.id))), ...prevState.slice(options.findIndex(i => parseInt(i.id) === parseInt(option.id)) + 1)]) }>Poista optio</button>
                            { options[options.length - 1].id !== option.id &&
                                <>
                                    <FontAwesomeIcon onClick={ () => moveOption(props.id, option.id, 'down') } icon='arrow-down' />
                                    <button onClick={ () => moveOption(props.id, option.id, 'down') }>Alas</button>
                                </>
                            }
                            { options[0].id !== option.id &&
                                <>
                                    <button onClick={ () => moveOption(props.id, option.id, 'up') }>Ylös</button>
                                    <FontAwesomeIcon onClick={ () => moveOption(props.id, option.id, 'up') } icon='arrow-up' />
                                </>
                            }
                        </div>
                        <Input 
                            inputtype = 'text'
                            name = 'name'
                            title = 'Nimi'
                            value = { options[options.findIndex(i => i.id === option.id)].name }
                            placeholder = 'Nimi'
                            handlechange = { (e) => setOptions(
                                prevState => [
                                    ...prevState.slice(0, prevState.findIndex(i => i.id === option.id)), 
                                    Object.assign({}, prevState[prevState.findIndex(i => i.id === option.id)], { name: e.target.value }), 
                                    ...prevState.slice(prevState.findIndex(i => i.id === option.id) + 1)
                                ]
                            ) }
                        />
                        <Input 
                            inputtype = 'text'
                            name = 'price'
                            title = 'Hinta'
                            value = { options[options.findIndex(i => i.id === option.id)].price }
                            placeholder = 'Hinta'
                            handlechange = { (e) => setOptions(
                                prevState => [
                                    ...prevState.slice(0, prevState.findIndex(i => i.id === option.id)), 
                                    Object.assign({}, prevState[prevState.findIndex(i => i.id === option.id)], { price: e.target.value }), 
                                    ...prevState.slice(prevState.findIndex(i => i.id === option.id) + 1)
                                ]
                            ) }
                        />
                        <Select 
                            title = 'Kerro option hinta tuotteen määrällä'
                            name = 'multiple_price_with_amount'
                            value = { options[options.findIndex(i => i.id === option.id)].multiple_price_with_amount }
                            options = { multipleAmount }
                            handlechange = { (e) => setOptions(
                                prevState => [
                                    ...prevState.slice(0, prevState.findIndex(i => i.id === option.id)), 
                                    Object.assign({}, prevState[prevState.findIndex(i => i.id === option.id)], { multiple_price_with_amount: parseInt(e.target.children[e.target.selectedIndex].value) }), 
                                    ...prevState.slice(prevState.findIndex(i => i.id === option.id) + 1)
                                ]
                            ) }
                        />
                        { options[options.findIndex(i => i.id === option.id)].multiple_price_with_amount === 1 &&
                            <Input 
                                inputtype = 'number'
                                name = 'first_unit_price'
                                title = 'Ensimmäisen yksikön hinta'
                                value = { options[options.findIndex(i => i.id === option.id)].first_unit_price }
                                placeholder = 'Ensimmäisen yksikön hinta'
                                handlechange = { (e) => setOptions(
                                    prevState => [
                                        ...prevState.slice(0, prevState.findIndex(i => i.id === option.id)), 
                                        Object.assign({}, prevState[prevState.findIndex(i => i.id === option.id)], { first_unit_price: e.target.value }), 
                                        ...prevState.slice(prevState.findIndex(i => i.id === option.id) + 1)
                                    ]
                                ) }
                            />
                        }
                    </div>
                )
            })}
            <Button 
                action = { () => setOptions(prevState => [...prevState, { id: parseInt(Math.max.apply(Math, options.map(function(i) { return i.id }))) + 1, name: '', price: '', multiple_price_with_amount: 0, first_unit_price: null }]) }
                type = 'btn btn-primary'
                title = 'Lisää optio'
            />
        </div>
    )
}

export default RadioOption