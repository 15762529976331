import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import Button from '../../components/formElements/button/button';
import Message from '../../components/message/message';
import Layout from '../../components/UI/layout/layout';
import AuthContext from '../../stores/authContext';

function DeleteRoles(props) {
    const { user, message, setMessage } = useContext(AuthContext)
    const [role, setRole] = useState('')

    useEffect(() => {
        if (user && user.token) {
            axios.get(`${process.env.REACT_APP_API_PATH}/api/roles/${props.match.params.id}`, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                setRole(res.data)
            }).catch(() => {
                setMessage({ message: 'Jotain meni pieleen.', class: 'Error' })
            })
        }
    }, [props.match.params.id, setMessage, user])

    function handleFormSubmit(e) {
        e.preventDefault()
        axios.delete(`${process.env.REACT_APP_API_PATH}/api/roles/${role.id}`, {
            headers: {
                'auth-token': user.token
            }
        }).then(() => {
            setMessage({ message: 'Kayttäjäryhmän poistaminen onnistui.', class: 'Success' })
            props.history.push('/kayttajaryhmat')
        }).catch(() => {
            setMessage({ message: 'Jotain meni pieleen.', class: 'Error' })
        })
    }

    return (
        <Layout>
            { message &&
                <Message 
                    classes = { message.class }
                    message = { message.message }
                    closeMessage = { () => setMessage(null) }
                />
            }
            { role &&
                <React.Fragment>
                    <h2>Oletko varma, että haluat poistaa käyttäjäryhmän { role.name }?</h2>
                    <Button 
                        action = { () => props.history.push('/kayttajaryhmat') }
                        type = 'btn btn-primary'
                        title = 'Takaisin'
                    />
                    <Button 
                        action = { handleFormSubmit }
                        type = 'btn btn-danger'
                        title = 'Poista'
                    />
                </React.Fragment>
            }
        </Layout>
    )
}

export default DeleteRoles