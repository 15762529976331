import { useContext, useState, useEffect, useCallback } from 'react';
import axios from 'axios';

import Layout from '../../components/UI/layout/layout';
import AuthContext from '../../stores/authContext';
import Input from '../../components/formElements/input/input';
import Select from '../../components/formElements/select/select';
import Button from '../../components/formElements/button/button';
import Message from '../../components/message/message';
import CategoryValidation from '../../validation/categoryValidation';
import Wrapper from '../../components/UI/wrapper/wrapper';
import CategoryTypeCheckbox from '../../components/formElements/checkbox/categoryTypeCheckbox';
import Checkbox from '../../components/formElements/checkbox/checkbox';
import FilteredItems from '../../components/filteredItems/filteredItems';
import Table from '../../components/UI/table/table';
import RoleCheckbox from '../../components/formElements/checkbox/roleCheckbox';

function CreateCategories(props) {
    const { message, setMessage, user } = useContext(AuthContext)
    const [name, setName] = useState('')
    const [machineName, setMachineName] = useState('')
    const [mainCategories, setMainCategories] = useState('')
    const [mainParentCategory, setMainParentCategory] = useState('')
    const [parentCategories, setParentCategories] = useState('')
    const [parentCategory, setParentCategory] = useState('')
    const [categoryTypes, setCategoryTypes] = useState('')
    const [selectedCategoryTypes, setSelectedCategoryTypes] = useState([])
    const [responsibilities, setResponsibilities] = useState([])
    const [selectedResponsibilities, setSelectedResponsibilities] = useState([])
    const [limitVisibility, setLimitVisibility] = useState(false)
    const [roles, setRoles] = useState('')
    const [selectedRoles, setSelectedRoles] = useState('')
    const [specialChars] = useState(/[` ~!@#$%^&*()_|+\-=?;:'",.<>{}[\]\\/]/gi)
    const [invalidFields, setInvalidFields] = useState([])
    const [products, setProducts] = useState([])
    const [productFilter, setProductFilter] = useState('')
    const [filteredProducts, setFilteredProducts] = useState([])
    const [selectedProducts, setSelectedProducts] = useState([])

    const getMainCategories = useCallback(() => {
        axios.get(`${process.env.REACT_APP_API_PATH}/api/categories`, {
            headers: {
                'auth-token': user.token
            }
        }).then(res => {
            setMainCategories(res.data)
        })

        axios.get(`${process.env.REACT_APP_API_PATH}/api/roles`, {
            headers: {
                'auth-token': user.token
            }
        }).then(res => {
            setRoles(res.data)
        })

        axios.get(`${process.env.REACT_APP_API_PATH}/api/products`, {
            headers: {
                'auth-token': user.token
            }
        }).then(res => {
            setProducts(res.data)
        })
    }, [user])

    useEffect(() => {
        if (user && user.token) {
            getMainCategories()

            axios.get(`${process.env.REACT_APP_API_PATH}/api/category-types`, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                setCategoryTypes(res.data)
            })

            axios.get(`${process.env.REACT_APP_API_PATH}/api/responsibilities`, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                setResponsibilities(res.data)
            })
        }
    }, [user, getMainCategories])

    useEffect(() => {
        if (mainParentCategory) {
            axios.get(`${process.env.REACT_APP_API_PATH}/api/categories/${mainParentCategory}`, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                setParentCategories(res.data)
            })
        }
    }, [mainParentCategory, user])

    function clearState() {
        setName('')
        setMachineName('')
        setMainParentCategory('')
        setParentCategories('')
        setParentCategory('')
        setSelectedCategoryTypes([])
        setSelectedResponsibilities([])
        setSelectedRoles([])
        setLimitVisibility(false)
        setSelectedProducts([])
        setInvalidFields([])
    }

    useEffect(() => {
        setMachineName(name.replace(specialChars, '_').replace(/[äå]/gi, 'a').replace(/[ö]/gi, 'o').toLocaleLowerCase())
    }, [name, specialChars])

    // set filtered products based on productFilter
    useEffect(() => {
        let filtered = []

        products.map(product => {
			if (productFilter.length > 1 && product.name.trim().toLocaleLowerCase().includes(productFilter.trim().toLocaleLowerCase()) && product.name.trim().toLocaleLowerCase() !== productFilter.trim().toLocaleLowerCase()) {
				filtered.push({
					name: product.name,
					id: product.id
				})
			}
		})

		setFilteredProducts(filtered)
    }, [productFilter, products])

    function handleSelectProducts(value) {
        if (selectedProducts && selectedProducts.filter(i => i.name === value).length > 0) {
            const index = selectedProducts.findIndex(i => i.name === value)
            setSelectedProducts(prevState => [...prevState.slice(0, index), ...prevState.slice(index + 1)])
        } else {
            setSelectedProducts(prevState => [...prevState, { name: value, id: products.filter(i => i.name.trim().toLocaleLowerCase() === value.trim().toLocaleLowerCase())[0].id }])
        }
        setFilteredProducts([])
    }

    function handleSelectCategoryTypes(categoryType) {
        if (selectedCategoryTypes && selectedCategoryTypes.filter(i => i.name === categoryType.name).length > 0) {
            const index = selectedCategoryTypes.findIndex(i => i.name === categoryType.name)
            setSelectedCategoryTypes(prevState => [...prevState.slice(0, index), ...prevState.slice(index + 1)])
        } else {
            setSelectedCategoryTypes(prevState => [...prevState, categoryType])
        }
    }

    function handleSelectResponsibilities(responsibility) {
        if (selectedResponsibilities && selectedResponsibilities.filter(i => i.name === responsibility.name).length > 0) {
            const index = selectedResponsibilities.findIndex(i => i.name === responsibility.name)
            setSelectedResponsibilities(prevState => [...prevState.slice(0, index), ...prevState.slice(index + 1)])
        } else {
            setSelectedResponsibilities(prevState => [...prevState, responsibility])
        }
    }

    function handleSelectRoles(role) {
        if (selectedRoles && selectedRoles.filter(i => i.name === role.name).length > 0) {
            const index = selectedRoles.findIndex(i => i.name === role.name)
            setSelectedRoles(prevState => [...prevState.slice(0, index), ...prevState.slice(index + 1)])
        } else {
            setSelectedRoles(prevState => [...prevState, role])
        }
    }

    function handleFormValidation(e) {
        e.preventDefault()
        const validation = CategoryValidation(name, machineName, selectedCategoryTypes)
        let message = []
        let invalid = []
        Object.entries(validation).forEach(([key, value]) => {
            if (!value.condition) {
                message.push(<li key={ key }>{ value.message }</li>)
                invalid.push(key)
            }
        })

        if (message.length > 0) {
            setInvalidFields(invalid)
            setMessage({ message: message, class: 'Error' })
            window.scrollTo({ top: 0, behavior: "smooth" })
        } else {
            handleFormSubmit()
        }
    }

    function handleFormSubmit() {
        let parent = 0

        if (parentCategory) {
            parent = parentCategory
        } else if (mainParentCategory) {
            parent  = mainParentCategory
        }

        const data = {
            name: name,
            machine_name: machineName,
            parent_category: parent,
            category_types: selectedCategoryTypes,
            category_responsibilities: selectedResponsibilities.length > 0 ? selectedResponsibilities : null,
            category_roles: selectedRoles.length > 0 ? selectedRoles : null,
            limit_visibility: limitVisibility,
            products: selectedProducts.length > 0 ? selectedProducts : null,
            position: 0
        }

        axios.post(`${process.env.REACT_APP_API_PATH}/api/admin/categories`, data, {
            headers: {
                'auth-token': user.token
            }
        }).then(() => {
            getMainCategories()
            clearState()
            setMessage({ message: 'Kategorian lisääminen onnistui.', class: 'Success' })
            window.scrollTo({ top: 0, behavior: "smooth" })
        }).catch(() => {
            setMessage({ message: 'Jotain meni pieleen.', class: 'Error' })
            window.scrollTo({ top: 0, behavior: "smooth" })
        })
    }

    return (
        <Layout>
            { message &&
                <Message 
                    classes = { message.class }
                    message = { message.message }
                    closeMessage = { () => setMessage(null) }
                />
            }
            <h1>Lisää kategoria</h1>
            <Input 
                inputtype = 'text'
                name = 'name'
                title = 'Nimi'
                value = { name }
                placeholder = 'Kategorian nimi'
                handlechange = { (e) => setName(e.target.value) }
                invalidFields = { invalidFields }
            />
            <Input 
                inputtype = 'text'
                name = 'machineName'
                title = 'Koneluettava nimi'
                value = { machineName }
                placeholder = 'Kategorian nimi'
                handlechange = { 
                    (e) => setMachineName(e.target.value.replace(specialChars, '_').replace(/[äå]/gi, 'a').replace(/[ö]/gi, 'o').toLocaleLowerCase()) 
                }
                invalidFields = { invalidFields }
            />
            { mainCategories &&
                <Select 
                    title = 'Pääkategoria'
                    name = 'mainParentCategory'
                    value = { mainParentCategory }
                    options = { mainCategories }
                    handlechange = { (e) => setMainParentCategory(e.target.children[e.target.selectedIndex].value) }
                    placeholder = { 'Valitse kategoria' }
                />
            }
            { parentCategories && parentCategories.length > 0 &&
                <Select 
                    title = 'Toisen tason yläkategoria'
                    name = 'parentCategory'
                    value = { parentCategory }
                    options = { parentCategories }
                    handlechange = { (e) => setParentCategory(e.target.children[e.target.selectedIndex].value) }
                    placeholder = { 'Valitse kategoria' }
                />
            }
            { categoryTypes &&
                <Wrapper>
                    <strong><label className='WrapperLabel'>Lisämääreet</label></strong>
                    { categoryTypes.map(category => {
                        return (
                            <CategoryTypeCheckbox 
                                key = { category.id }
                                item = { category }
                                action = { () =>  handleSelectCategoryTypes(category) }
                                selected = { selectedCategoryTypes }
                                checked = {
                                    selectedCategoryTypes.filter(i => i.id === category.id).length > 0 ?
                                        true 
                                    : null
                                }
                            />
                        )
                    }) }
                </Wrapper>
            }
            { responsibilities &&
                <Wrapper>
                    <strong><label className='WrapperLabel'>Vastuu</label></strong>
                    { responsibilities.map(responsibility => {
                        return (
                            <Checkbox 
                                key = { responsibility.id }
                                id = { responsibility.id }
                                name = { responsibility.name }
                                action = { () =>  handleSelectResponsibilities(responsibility) }
                                selected = { selectedResponsibilities }
                                checked = {
                                    selectedResponsibilities.filter(i => i.id === responsibility.id).length > 0 ?
                                        true 
                                    : null
                                }
                            />
                        )
                    }) }
                </Wrapper>
            }
            <Wrapper>
                <strong><label className='WrapperLabel'>Näkyvyys</label></strong>
                <Checkbox
                    id = 'limitVisibility'
                    name = 'Näytä kategoria vain valituille ryhmille'
                    action = { () => setLimitVisibility(!limitVisibility) }
                    checked = { limitVisibility }
                />
            </Wrapper>
            { roles && limitVisibility &&
                <Wrapper>
                    <strong><label className='WrapperLabel'>Käyttäjäryhmät</label></strong>
                        { roles.map(role => {
                            return (
                                <RoleCheckbox 
                                    key = { role.id }
                                    item = { role }
                                    action = { () =>  handleSelectRoles(role) }
                                    checked = {
                                        selectedRoles && selectedRoles.filter(i => i.id === role.id).length > 0 ?
                                            true 
                                        : null
                                    }
                                />
                            )
                        }) }
                </Wrapper>
            }
            { products &&
                <Wrapper>
                    <strong><label className='WrapperLabel'>Tuotteet</label></strong>
                    <input className='SearchField' value={ productFilter } placeholder='Hae tuotteita' onChange={ (e) => setProductFilter(e.target.value) } />
                    { filteredProducts && filteredProducts.length > 0 &&
                        <FilteredItems 
                            items = { filteredProducts }
                            action = { (name) => handleSelectProducts(name) }
                        />
                    }
                </Wrapper>
            }
            { selectedProducts && selectedProducts.length > 0 &&
                <Wrapper>
                    <strong><label className='WrapperLabel'>Valitut tuotteet</label></strong>
                    <Table>
                        <thead>
                            <tr>
                                <th>Nimi</th>
                                <th>Toiminnot</th>
                            </tr>
                        </thead>
                        <tbody>
                            { selectedProducts.map(product => {
                                return (
                                    <tr key={ product.id }>
                                        <td>{ product.name }</td>
                                        <td>
                                            <button className='RemoveButton' onClick={ () => setSelectedProducts(prevState => [...prevState.filter(i => i.id !== product.id)]) }>Poista</button>
                                        </td>
                                    </tr>
                                )
                            }) }
                        </tbody>
                    </Table>
                </Wrapper>
            
            }
            <Button 
                action = { handleFormValidation }
                type = 'btn btn-primary'
                title = 'Tallenna'
            />
        </Layout>
    )
}

export default CreateCategories