import React, { useContext, useState, useEffect } from 'react';

import './successBlock.scss';
import GuideContext from '../../../stores/guideContext';
import BlockActions from '../components/blockActions/blockActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Textarea from '../../formElements/textarea/textarea';

function SuccessBlock(props) {
    const { pageContent, setPageContent, drag, dragEnd } = useContext(GuideContext)
    const [draggable, setDraggable] = useState(false)
    const [count, setCount] = useState('')
    const [successMessage, setSuccessMessage] = useState('Hienoa!')

    useEffect(() => {
        if (props.initialValue) {
            setSuccessMessage(props.initialValue)
        }
    }, [props.initialValue])

    useEffect(() => {
        const index = pageContent.filter(i => i.type === 'success-block').findIndex(i => i.id === props.id)
        !count && setCount(index + 1)
    }, [props.id, pageContent, count])

    useEffect(() => {
        setPageContent(
            prevState => [
                ...prevState.slice(0, prevState.findIndex(i => i.id === props.id)), 
                Object.assign({}, prevState[prevState.findIndex(i => i.id === props.id)], { value: successMessage, type: 'success-block', parent: props.parent }), 
                ...prevState.slice(prevState.findIndex(i => i.id === props.id) + 1)
            ]
        )
    }, [successMessage, props.id, props.parent, setPageContent])

    return (
        <div 
            className = 'SuccessBlock'
            draggable = { draggable }
            id = { props.id }
            onDrag = { (e) => drag(e) }
            onDragEnd = { () => dragEnd() }
        >
            <div>
                <BlockActions 
                    id = { props.id } 
                    parent = { props.parent } 
                    name = { 'Onnistuminen ' + count } 
                />
                <FontAwesomeIcon 
                    onMouseOver = { () => !draggable ? setDraggable(true) : null } 
                    onMouseLeave = { () => draggable ? setDraggable(false) : null }
                    icon = 'arrows-alt' 
                />
            </div>
            <Textarea 
                name = 'successMessage'
                value = { successMessage }
                rows = { 5 }
                handlechange = { (e) => setSuccessMessage(e.target.value) }
            />
        </div>
    )
}

export default SuccessBlock