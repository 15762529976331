const SettingsValidation = (price, email) => {
    const validation = ({
        price: {
            condition: price,
            message: 'Tuntihinta on pakollinen kenttä'
        },
        email: {
            condition: email,
            message: 'Sähköpostiosoite on pakollinen kenttä'
        }
    })

    return validation
}

export default SettingsValidation