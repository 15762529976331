import React from 'react';

import './wrapper.scss';

const Wrapper = (props) => (
    <div className={ props.invalidFields && props.invalidFields.includes(props.name) ? 'Wrapper Invalid' : 'Wrapper' }>
        { props.children }
    </div>
)

export default Wrapper