import React, { useContext, useState, useEffect } from 'react';

import './conditionalBlock.scss';
import GuideContext from '../../../stores/guideContext';
import Input from '../../formElements/input/input';
import OptionBlock from '../optionBlock/optionBlock';
import BlockActions from '../components/blockActions/blockActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function ConditionalBlock(props) {
    const { pageBlocks, pageContent, setPageContent, handleSetPageBlocks, getNextKey, getNextId, drag, dragEnd, removeBlock } = useContext(GuideContext)
    const [draggable, setDraggable] = useState(false)
    const [header, setHeader] = useState('')
    const [edit, setEdit] = useState(false)
    const [count, setCount] = useState('')
    const [showBlocks, setShowBlocks] = useState(true)

    useEffect(() => {
        setHeader(props.initialValue)
    }, [props.initialValue])

    useEffect(() => {
        const index = pageContent.filter(i => i.type === 'conditional-block').findIndex(i => i.id === props.id)
        !count && setCount(index + 1)
    }, [props.id, pageContent, count])

    useEffect(() => {
        props.showEdit && setEdit(true)
    }, [props.showEdit])

    const handleChange = (e) => {
        setHeader(e.target.value)
        setPageContent(
            prevState => [
                ...prevState.slice(0, prevState.findIndex(i => i.id === props.id)), 
                Object.assign({}, prevState[prevState.findIndex(i => i.id === props.id)], { value: e.target.value, type: 'conditional-block', parent: props.parent }), 
                ...prevState.slice(prevState.findIndex(i => i.id === props.id) + 1)
            ]
        )
    }

    const handleAddOption = () => {
        setEdit(false)
        setShowBlocks(true)
        handleSetPageBlocks(
            <OptionBlock
                key = { getNextKey() } 
                id = { getNextId() }
                parent = { props.id }
                showEdit = { true }
                level = { props.level }
            />, 'option-block'
        ) 
    }

    return (
        <div 
            className = { 'ConditionalBlock Level-' + props.level }
            draggable = { draggable }
            id = { props.id }
            onDrag = { (e) => drag(e) }
            onDragEnd = { () => dragEnd() }
        >
            <div>
                <BlockActions 
                    id = { props.id } 
                    parent = { props.parent } 
                    edit = { true } 
                    editAction = { () => setEdit(true) }
                    name = { <span className={ 'Header-Level-' + props.level }>{ 'Ehdollinen ' + count }</span> }
                    showAddOption = { true }
                    addOption = { handleAddOption }
                    showChevron = { true }
                    showBlocks = { showBlocks }
                    handleSetShowBlocks = { () => setShowBlocks(!showBlocks) }
                />
                <FontAwesomeIcon 
                    onMouseOver = { () => !draggable ? setDraggable(true) : null } 
                    onMouseLeave = { () => draggable ? setDraggable(false) : null }
                    icon = 'arrows-alt' 
                />
            </div>
            <div className={ !showBlocks ? 'Blocks Hidden' : 'Blocks'}>
                <h2 style={{ marginBottom: '15px', marginLeft: '10px' }}>{ header }</h2>
            </div>
            { edit &&
                <React.Fragment>
                    <div onClick={ () => setEdit(false) } className='Backdrop'></div>
                    <div className='Popup'>
                        <button className='Close' onClick={ () => setEdit(false) }>Sulje</button>
                        <Input 
                            inputtype = 'text'
                            name = 'conditional'
                            title = 'Otsikko'
                            value = { header }
                            placeholder = 'Otsikko'
                            handlechange = { handleChange }
                        />
                        <button className='btn btn-primary' onClick={ () => setEdit(false) }>Lisää</button>
                        <button className='btn btn-danger' onClick={ () => removeBlock(props.id) }>Poista</button>
                    </div>
                </React.Fragment>
            }
            { pageBlocks &&
                <div className={ !showBlocks ? 'Blocks Hidden' : 'Blocks'}>
                    { pageBlocks.filter(i => i.props.parent === props.id) }
                </div>
            }
        </div>
    )
}

export default ConditionalBlock