import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import Layout from '../../components/UI/layout/layout';
import AuthContext from '../../stores/authContext';

function ShowReports(props) {
    const [report, setReport] = useState('')
    const { user } = useContext(AuthContext)

    useEffect(() => {
        if (user && user.token) {
            axios.get(`${process.env.REACT_APP_API_PATH}/api/fault-reports/${props.match.params.id}`, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                setReport(res.data)
            })
        }
    }, [props.match.params.id, user])

    return (
        <Layout>
            { report &&
                <div>
                    <h1>Vikailmoitus</h1>
                    <div style={{ margin: '30px 0', background: '#D3E0EB', padding: '15px' }}>
                        <p><strong>Taloyhtiö:</strong> { report.house }</p>
                        <p><strong>Osoite:</strong> { report.address }</p>
                        <p><strong>Asunnon numero:</strong> { report.house_number }</p>
                        <p><strong>Nimi:</strong> { report.name }</p>
                        <p><strong>Puhelinnumero:</strong> { report.phone }</p>
                        <p><strong>Sähköpostiosoite:</strong> { report.email }</p>
                        <p><strong>Tila:</strong> { report.category_type }</p>
                        <p><strong>Mihin vika liittyy:</strong> { report.categories }</p>
                        <p><strong>Vika:</strong> { report.symptom }</p>
                        <p><strong>Kuvaus viasta:</strong> { report.description }</p>
                        <p><strong>Saako sisään mennä yleisavaimella?</strong> { report.public_key === 1 ? 'Kyllä' : 'Ei' }</p>
                        <p><strong>Onko asunnossa eläimiä?</strong> { report.animals_in_house === 1 ? 'Kyllä' : 'Ei' }</p>
                        <p><strong>Toiveet:</strong> { report.wishes }</p>
                        <p><strong>Ilmoitus jätetty:</strong> { `${new Date(report.created_at).getDate()}.${new Date(report.created_at).getMonth() + 1}.${new Date(report.created_at).getFullYear()} ${new Date(report.created_at).getHours()}:${new Date(report.created_at).getMinutes()}` }</p>
                    </div>
                </div>
            }
        </Layout>
    )
}

export default ShowReports