import React from 'react';

import './table.scss';

const Table = (props) => (
    <div className='table-responsive'>
        <table className='table table-striped'>
            { props.children }
        </table>
    </div>
)

export default Table