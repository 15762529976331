import { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { NavLink } from 'react-router-dom';

import Button from '../../components/formElements/button/button';
import Message from '../../components/message/message';
import Layout from '../../components/UI/layout/layout';
import AuthContext from '../../stores/authContext';
import Table from '../../components/UI/table/table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function PolicyCategoriesIndex(props) {
    const { message, setMessage, user } = useContext(AuthContext)
    const [categories, setCategories] = useState('')
    const [filter, setFilter] = useState('')
    const [selectedCategories, setSelectedCategories] = useState([])

    useEffect(() => {
        if (user && user.token) {
            axios.get(`${process.env.REACT_APP_API_PATH}/api/admin/policies/categories`, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                const sortedByParent = res.data.sort((a, b) => a.parent_category > b.parent_category ? 1 : -1)
                const sortedCategories = []
                sortedByParent.map(category => {
                    const parentIndex = sortedByParent.findIndex(i => i.id === category.parent_category)
                    if (!category.parent_category) {
                        category.level = 1
                        return sortedCategories.push(category)
                    } else if (sortedByParent[parentIndex] && sortedByParent[parentIndex].level === 1) {
                        category.level = 2
                        return sortedCategories.splice(sortedCategories.findIndex(i => i.id === category.parent_category), 0, category)
                    } else {
                        category.level = 3
                        return sortedCategories.splice(sortedCategories.findIndex(i => i.id === category.parent_category), 0, category)
                    }
                })
                setCategories(sortedCategories.reverse())
            })
        }
    }, [user])

    function handleSetSelectedCategories(category) {
        if (selectedCategories && selectedCategories.filter(i => i.id === category.id).length > 0) {
            const index = selectedCategories.findIndex(i => i.id === category.id)
            setSelectedCategories(prevState => [...prevState.slice(0, index), ...prevState.slice(index + 1)])
        } else {
            setSelectedCategories(prevState => [...prevState, category])
        }
    }

    return (
        <Layout>
            { message &&
                <Message 
                    classes = { message.class }
                    message = { message.message }
                    closeMessage = { () => setMessage(null) }
                />
            }
            <h1>Kategoriat</h1>
            <Button 
                action = { () => props.history.push('/toimintatavat/kategoriat/lisaa') }
                type = 'btn btn-primary'
                title = 'Lisää uusi'
            />
            <input style={{ float: 'right', padding: '10px' }} value={ filter } placeholder='Hae...' onChange={ (e) => setFilter(e.target.value) } />
            { categories &&
                <Table>
                    <thead>
                        <tr>
                            <th>Nimi</th>
                            <th>Yläkategoria</th>
                            <th>Toiminnot</th>
                        </tr>
                    </thead>
                    <tbody className='CategoryList'>
                        { categories.map(category => {
                            if ((!filter || category.name.toLowerCase().includes(filter.toLowerCase())) && (category.parent_category === 0 || selectedCategories.filter(i => i.id === category.parent_category).length > 0)) {
                                return (
                                    <tr key={ category.id }>
                                        <td onClick={ () => handleSetSelectedCategories(category) } className={ 'Level-' + category.level }>
                                            { category.parent_category === 0 && selectedCategories.filter(i => i.id === category.id).length === 0 &&
                                                <FontAwesomeIcon icon='chevron-down' />
                                            }
                                            { category.parent_category === 0 && selectedCategories.filter(i => i.id === category.id).length > 0 &&
                                                <FontAwesomeIcon icon='chevron-up' />
                                            }
                                            { category.name }
                                        </td>
                                        <td>
                                            { categories[categories.findIndex(i => i.id === category.parent_category)] && categories[categories.findIndex(i => i.id === category.parent_category)].name }
                                        </td>
                                        <td>
                                            <NavLink to={`/toimintatavat/kategoriat/muokkaa/${category.id}`}>
                                                Muokkaa
                                            </NavLink>
                                            <NavLink to={`/toimintatavat/kategoriat/poista/${category.id}`}>
                                                Poista
                                            </NavLink>
                                        </td>
                                    </tr>
                                )
                            } else {
                                return null
                            }
                        })}
                    </tbody>
                </Table>
            }
        </Layout>
    )
}

export default PolicyCategoriesIndex