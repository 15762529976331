import React from 'react';

import './sidebarLeft.scss';

const SidebarLeft = (props) => (
    <div className='SidebarLeftWrapper'>
        <div className='SidebarLeft'>
            { props.children }
        </div>
    </div>
)

export default SidebarLeft