import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';

import Layout from '../../components/UI/layout/layout';
import AuthContext from '../../stores/authContext';
import Message from '../../components/message/message';
import ProductValidation from '../../validation/productValidation';
import Input from '../../components/formElements/input/input';
import Button from '../../components/formElements/button/button';
import Wrapper from '../../components/UI/wrapper/wrapper';
import CategoryCheckbox from '../../components/formElements/checkbox/categoryCheckbox';
import MainContent from '../../components/UI/mainContent/mainContent';
import Sidebar from '../../components/UI/sidebar/sidebar';
import ProductContext from '../../stores/productContext';
import CheckboxOption from '../../components/options/checkboxOption/checkboxOption';
import RadioOption from '../../components/options/radioOption/radioOption';
import Textarea from '../../components/formElements/textarea/textarea';
import Select from '../../components/formElements/select/select';
import HouseOption from '../../components/options/houseOption/houseOption';
import ImageInput from '../../components/formElements/imageInput/imageInput';
import ImagePreview from '../../components/formElements/imagePreview/imagePreview';

function EditProducts(props) {
    const { message, setMessage, user } = useContext(AuthContext)
    const [name, setName] = useState('')
    const [machineName, setMachineName] = useState('')
    const [description, setDescription] = useState('')
    const [image, setImage] = useState('')
    const [imageID, setImageID] = useState('')
    const [imageName, setImageName] = useState('')
    const [units, setUnits] = useState([])
    const [unit, setUnit] = useState('')
    const [price, setPrice] = useState('')
    const [time, setTime] = useState('')
    const [responsibility, setResponsibility] = useState('')
    const [selectedCategories, setSelectedCategories] = useState([])
    const [categories, setCategories] = useState([])
    const [houses, setHouses] = useState([])
    const [responsibilities, setResponsibilities] = useState([])
    const [productTypes, setProductTypes] = useState([])
    const [productType, setProductType] = useState('')
    const [priceForAllHouses, setPriceForAllHouses] = useState('')
    const [showPriceForAllHouses, setShowPriceForAllHouses] = useState(true)
    const [specialChars] = useState(/[` ~!@#$%^&*()_|+\-=?;:'",.<>{}[\]\\/]/gi)
    const [invalidFields, setInvalidFields] = useState([])
    const { optionInputs, setOptionInputs, optionValues, setOptionValues, handleSetOptions, getNextKey, getNextId, getNextGroup, clearOptions, houseInputs, setHouseInputs, houseValues, setHouseValues, handleSetHouses, getNextHouseKey, getNextHouseId } = useContext(ProductContext)

    useEffect(() => {
        if (user && user.token) {
            clearOptions()

            axios.get(`${process.env.REACT_APP_API_PATH}/api/responsibilities`).then(res => {
                setResponsibilities(res.data)
            })

            axios.get(`${process.env.REACT_APP_API_PATH}/api/units`).then(res => {
                setUnits(res.data)
            })

            axios.get(`${process.env.REACT_APP_API_PATH}/api/products/categories`).then(res => {
                setCategories(res.data)
            })

            axios.get(`${process.env.REACT_APP_API_PATH}/api/products/product_types`).then(res => {
                setProductTypes(res.data)
            })

            axios.get(`${process.env.REACT_APP_API_PATH}/api/houses`, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                setHouses(res.data)
                const houseArray = res.data

                axios.get(`${process.env.REACT_APP_API_PATH}/api/products/product_house_prices/${props.match.params.id}`).then(res => {
                    res.data.map(house => {
                        return (
                            setHouseInputs(prevState => [...prevState, 
                                <HouseOption 
                                    key = { house.id } 
                                    id = { house.id }
                                    house = { { name: house.house_name, id: house.house_id } }
                                    price = { house.price }
                                    houses = { houseArray }
                                />
                            ]),
            
                            setHouseValues(prevState => [...prevState, {
                                id: house.id,
                                house: { name: house.house_name, id: house.house_id },
                                price: house.price
                            }])
                        )
                    })
                })
            })

            axios.get(`${process.env.REACT_APP_API_PATH}/api/products/${props.match.params.id}`).then(res => {
                setName(res.data.name)
                setMachineName(res.data.machine_name)
                res.data.description && setDescription(res.data.description)
                res.data.image_id && setImageID(res.data.image_id)
                res.data.path && setImageName(res.data.path)
                res.data.price && setPrice(res.data.price)
                res.data.price_for_all_houses ? setPriceForAllHouses(res.data.price_for_all_houses) : setShowPriceForAllHouses(false)
                res.data.time && setTime(res.data.time)
                res.data.product_type_id && setProductType(res.data.product_type_id)
                res.data.responsibility_id && setResponsibility(res.data.responsibility_id)
                res.data.unit_id && setUnit(res.data.unit_id)
            })

            axios.get(`${process.env.REACT_APP_API_PATH}/api/products/product_categories/${props.match.params.id}`).then(res => {
                setSelectedCategories(res.data)
            })
        }
    }, [props.match.params.id, clearOptions, user, setHouseInputs, setHouseValues])

    useEffect(() => {
        units && units.length > 0 && axios.get(`${process.env.REACT_APP_API_PATH}/api/products/product_option_groups/${props.match.params.id}`).then(res => {
            res.data.map(option => {
                return axios.get(`${process.env.REACT_APP_API_PATH}/api/products/product_options/${option.id}`).then(res => {
                    const options = res.data
                    if (option.type === 'checkbox') {
                        setOptionInputs(prevState => [...prevState, 
                            <CheckboxOption 
                                key = { option.id } 
                                id = { option.id }
                                group_id = { option.id }
                                name = { option.name }
                                type = { option.type }
                                required = { option.required }
                                options = { options }
                                units = { units }
                            />
                        ])

                        setOptionValues(prevState => [...prevState, {
                            id: option.id,
                            type: 'checkbox',
                            group_id: option.id,
                            options: options
                        }])
                    } else if (option.type === 'radio') {
                        setOptionInputs(prevState => [...prevState, 
                            <RadioOption 
                                key = { option.id } 
                                id = { option.id }
                                group_id = { option.id }
                                name = { option.name }
                                type = { option.type }
                                required = { option.required }
                                options = { options }
                                units = { units }
                            />
                        ])

                        setOptionValues(prevState => [...prevState, {
                            id: option.id,
                            type: 'radio',
                            group_id: option.id,
                            options: options
                        }])
                    }
                })
            })
        })
    }, [units, props.match.params.id, setOptionInputs, setOptionValues])

    useEffect(() => {
        if (image && user && user.token) {
            const fd = new FormData()
            fd.append('image', image, image.name)
            axios.post(`${process.env.REACT_APP_API_PATH}/api/images/upload`, fd, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                if (res.data.error) {
                    setMessage({ message: res.data.error, class: 'Error' })
                    window.scrollTo({ top: 0, behavior: "smooth" })
                } else {
                    setImageID(res.data.id)
                    setImageName(res.data.name)
                }
            })
        }
    }, [image, user, setMessage])

    function handleSelectCategories(category) {
        if (selectedCategories && selectedCategories.filter(i => i.name === category.name).length > 0) {
            const index = selectedCategories.findIndex(i => i.name === category.name)
            setSelectedCategories(prevState => [...prevState.slice(0, index), ...prevState.slice(index + 1)])
        } else {
            setSelectedCategories(prevState => [...prevState, category])
        }
    }

    function handleShowPriceForAllHouses() {
        if (showPriceForAllHouses) {
            setShowPriceForAllHouses(false)
            setPriceForAllHouses(null)
        } else {
            setShowPriceForAllHouses(true)
        }
    }

    function handleFormValidation(e) {
        e.preventDefault()
        const validation = ProductValidation(name, machineName, responsibility, productType, time, unit, price, selectedCategories)
        let message = []
        let invalid = []
        Object.entries(validation).forEach(([key, value]) => {
            if (!value.condition) {
                message.push(<li key={ key }>{ value.message }</li>)
                invalid.push(key)
            }
        })

        if (message.length > 0) {
            setInvalidFields(invalid)
            setMessage({ message: message, class: 'Error' })
            window.scrollTo({ top: 0, behavior: "smooth" })
        } else {
            handleFormSubmit()
        }
    }

    function handleFormSubmit() {
        const data = {
            name: name,
            machine_name: machineName,
            description: description,
            image_id: imageID ? imageID : 1,
            price: price ? price : null,
            price_for_all_houses: priceForAllHouses ? priceForAllHouses : null,
            time: time ? time : null,
            responsibility_id: responsibility,
            product_type_id: productType,
            unit_id: unit ? unit : 0,
            product_product_categories: selectedCategories,
            product_house_prices: houseValues,
            product_options: optionValues
        }

        axios.patch(`${process.env.REACT_APP_API_PATH}/api/products/${props.match.params.id}`, data, {
            headers: {
                'auth-token': user.token
            }
        }).then(() => {
            setInvalidFields([])
            setMessage({ message: 'Tuotteen muokkaaminen onnistui.', class: 'Success' })
            window.scrollTo({ top: 0, behavior: "smooth" })
        }).catch(() => {
            setMessage({ message: 'Jotain meni pieleen.', class: 'Error' })
            window.scrollTo({ top: 0, behavior: "smooth" })
        })
    }

    return (
        <Layout>
            <div className='ProductForm'>
                <MainContent>
                    { message &&
                        <Message 
                            classes = { message.class }
                            message = { message.message }
                            closeMessage = { () => setMessage(null) }
                        />
                    }
                    <h1>Muokkaa tuotetta</h1>
                    <Input 
                        inputtype = 'text'
                        name = 'name'
                        title = 'Nimi'
                        value = { name }
                        placeholder = 'Nimi'
                        handlechange = { (e) => setName(e.target.value) }
                        invalidFields = { invalidFields }
                    />
                    <Input 
                        inputtype = 'text'
                        name = 'machineName'
                        title = 'Koneluettava nimi'
                        value = { machineName }
                        placeholder = 'Nimi'
                        handlechange = { 
                            (e) => setMachineName(e.target.value.replace(specialChars, '_').replace(/[äå]/gi, 'a').replace(/[ö]/gi, 'o').toLocaleLowerCase()) 
                        }
                        invalidFields = { invalidFields }
                    />
                    <Textarea 
                        name = 'description'
                        value = { description }
                        rows = { 5 }
                        title = 'Kuvaus'
                        handlechange = { (e) => setDescription(e.target.value) }
                        placeholder = 'Kuvaus'
                        invalidFields = { invalidFields }
                    />
                    <ImageInput
                        name = 'image'
                        title = 'Kuva'
                        handlechange = { (e) => setImage(e.target.files[0]) }
                        invalidFields = { invalidFields }
                    />
                    { imageID && 
                        <ImagePreview 
                            image = { `${process.env.REACT_APP_API_PATH}/images/${imageName}` }
                            removeImage = { () => setImageID(null) }
                        />
                    }
                    <Select 
                        title = 'Vastuu'
                        name = 'responsibility'
                        value = { responsibility }
                        options = { responsibilities }
                        handlechange = { (e) => setResponsibility(e.target.children[e.target.selectedIndex].value) }
                        placeholder = { 'Vastuu' }
                        invalidFields = { invalidFields }
                    />
                    <Select 
                        title = 'Tuotteen tyyppi'
                        name = 'productType'
                        value = { productType }
                        options = { productTypes }
                        handlechange = { (e) => setProductType(parseInt(e.target.children[e.target.selectedIndex].value)) }
                        placeholder = { 'Tuotteen tyyppi' }
                        invalidFields = { invalidFields }
                    />
                    { productType === 1 &&
                        <Input 
                            inputtype = 'number'
                            name = 'time'
                            title = 'Aika (min)'
                            value = { time }
                            placeholder = 'Aika'
                            handlechange = { (e) => setTime(e.target.value) }
                            invalidFields = { invalidFields }
                        />
                    }
                    { productType === 2 &&
                        <React.Fragment>
                            <Select 
                                title = 'Yksikkö'
                                name = 'unit'
                                value = { unit }
                                options = { units }
                                handlechange = { (e) => setUnit(e.target.children[e.target.selectedIndex].value) }
                                placeholder = { 'Yksikkö' }
                                invalidFields = { invalidFields }
                            />
                            <Input 
                                inputtype = 'number'
                                name = 'price'
                                title = 'Yksikköhinta'
                                value = { price }
                                placeholder = 'Yksikköhinta'
                                handlechange = { (e) => setPrice(e.target.value) }
                                invalidFields = { invalidFields }
                            />
                        </React.Fragment>
                    }
                    <Button 
                        action = { () => handleSetHouses(
                            <HouseOption 
                                key = { getNextHouseKey() } 
                                id = { getNextHouseId() } 
                                houses = { houses }
                            />,
                            { 
                                id: getNextHouseId(),
                            }
                        ) }
                        type = 'btn btn-primary'
                        title = 'Lisää taloyhtiö'
                    />
                    <Button 
                        action = { () => handleShowPriceForAllHouses() }
                        type = 'btn btn-primary'
                        title = 'Lisää hinta kaikille taloyhtiöille'
                    />
                    { houseInputs.map(house => {
                        return (
                            <div key={house.key}>
                                { house }
                            </div>
                        )
                    }) }
                    { showPriceForAllHouses &&  
                        <Input 
                            inputtype = 'number'
                            name = 'priceForAllHouses'
                            title = 'Hinta kaikille taloyhtiöille'
                            value = { priceForAllHouses }
                            placeholder = 'Hinta kaikille taloyhtiöille'
                            handlechange = { (e) => setPriceForAllHouses(e.target.value) }
                        />
                    }
                    <h2 style={{ marginBottom: '15px' }}>Optioryhmät</h2>
                    <div style={{ marginBottom: '30px' }}>
                        <Button 
                            action = { () => handleSetOptions(
                                <CheckboxOption 
                                    key = { getNextKey() } 
                                    id = { getNextId() } 
                                    group_id = { getNextGroup() }
                                    units = { units }
                                />,
                                { 
                                    id: getNextId(),
                                    type: 'checkbox',
                                    group_id: getNextGroup()
                                }
                            ) }
                            type = 'btn btn-primary'
                            title = 'Monivalinta'
                        />
                        <Button 
                            action = { () => handleSetOptions(
                                <RadioOption 
                                    key = { getNextKey() } 
                                    id = { getNextKey() } 
                                    group_id = { getNextGroup() }
                                    units = { units }
                                />,
                                { 
                                    id: getNextId(),
                                    type: 'radio',
                                    group_id: getNextGroup()
                                }
                            ) }
                            type = 'btn btn-primary'
                            title = 'Yksittäisvalinta'
                        />
                        { optionInputs.map(option => {
                            return (
                                <div key={option.key}>
                                    { option }
                                </div>
                            )
                        }) }
                    </div>
                    <Button 
                        action = { handleFormValidation }
                        type = 'btn btn-primary'
                        title = 'Tallenna'
                    />
                </MainContent>
                <Sidebar>
                    { categories &&
                        <Wrapper>
                            <strong><label className='WrapperLabel'>Kategoriat</label></strong>
                            { categories.map(category => {
                                return (
                                    <CategoryCheckbox 
                                        key = { category.id }
                                        category = { category }
                                        action = { () =>  handleSelectCategories(category) }
                                        selected = { selectedCategories }
                                        checked = {
                                            selectedCategories.filter(i => i.id === category.id).length > 0 ?
                                                true 
                                            : null
                                        }
                                    />
                                )
                            }) }
                        </Wrapper>
                    }
                </Sidebar>
            </div>
        </Layout>
    )
}

export default EditProducts