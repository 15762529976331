import { useContext, useState, useEffect } from 'react';
import axios from 'axios';

import Layout from '../../components/UI/layout/layout';
import AuthContext from '../../stores/authContext';
import Button from '../../components/formElements/button/button';
import Message from '../../components/message/message';
import MainContent from '../../components/UI/mainContent/mainContent';
import RoleCheckbox from '../../components/formElements/checkbox/roleCheckbox';
import HouseTabValidation from '../../validation/houseTabValidation';
import Input from '../../components/formElements/input/input';
import Sidebar from '../../components/UI/sidebar/sidebar';
import Wrapper from '../../components/UI/wrapper/wrapper';

function EditHouseTabs(props) {
    const { message, setMessage, user } = useContext(AuthContext)
    const [name, setName] = useState('')
    const [roles, setRoles] = useState([])
    const [selectedRoles, setSelectedRoles] = useState([])
    const [invalidFields, setInvalidFields] = useState([])

    useEffect(() => {
        if (user && user.token) {
            axios.get(`${process.env.REACT_APP_API_PATH}/api/roles`, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                setRoles(res.data)
            })
    
            axios.get(`${process.env.REACT_APP_API_PATH}/api/house-tabs/${props.match.params.id}`, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                setName(res.data.name)
            })

            axios.get(`${process.env.REACT_APP_API_PATH}/api/house-tabs/${props.match.params.id}/roles`, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                res.data && res.data.length > 0 && res.data[0].id && setSelectedRoles(res.data)
            })
        }
    }, [user, props.match.params.id])


    function handleSelectRoles(role) {
        if (selectedRoles && selectedRoles.filter(i => i.name === role.name).length > 0) {
            const index = selectedRoles.findIndex(i => i.name === role.name)
            setSelectedRoles(prevState => [...prevState.slice(0, index), ...prevState.slice(index + 1)])
        } else {
            setSelectedRoles(prevState => [...prevState, role])
        }
    }

    function handleFormValidation(e) {
        e.preventDefault()
        const validation = HouseTabValidation(name)
        let message = []
        let invalid = []
        Object.entries(validation).forEach(([key, value]) => {
            if (!value.condition) {
                message.push(<li key={ key }>{ value.message }</li>)
                invalid.push(key)
            }
        })

        if (message.length > 0) {
            setInvalidFields(invalid)
            setMessage({ message: message, class: 'Error' })
            window.scrollTo({ top: 0, behavior: "smooth" })
        } else {
            handleFormSubmit()
        }
    }

    function handleFormSubmit() {
        const data = {
            name: name,
            roles: selectedRoles
        }

        axios.patch(`${process.env.REACT_APP_API_PATH}/api/house-tabs/${props.match.params.id}`, data, {
            headers: {
                'auth-token': user.token
            }
        }).then(() => {
            setMessage({ message: 'Välilehden muokkaaminen onnistui.', class: 'Success' })
            window.scrollTo({ top: 0, behavior: "smooth" })
        }).catch(() => {
            setMessage({ message: 'Jotain meni pieleen.', class: 'Error' })
            window.scrollTo({ top: 0, behavior: "smooth" })
        })
    }

    function goBack() {
        setMessage(null)
        props.history.push('/kiinteistot/valilehdet')
    }

    return (
        <Layout>
            <div className='GuideForm'>
                <MainContent>
                    { message &&
                        <Message 
                            classes = { message.class }
                            message = { message.message }
                            closeMessage = { () => setMessage(null) }
                        />
                    }
                    { name &&
                        <h1>{ name }</h1>
                    }           
                    <form onSubmit={ handleFormValidation }>
                        <Input 
                            inputtype = 'text'
                            name = 'name'
                            title = 'Otsikko'
                            value = { name }
                            placeholder = 'Otsikko'
                            handlechange = { (e) => setName(e.target.value) }
                            invalidFields = { invalidFields }
                        />
                        <Button 
                            action = { handleFormValidation }
                            type = 'btn btn-primary'
                            title = 'Tallenna'
                        />
                        <Button 
                            action = { goBack }
                            type = 'btn btn-primary'
                            title = 'Takaisin'
                        />
                    </form>
                </MainContent>
                <Sidebar>
                    <Wrapper
                        name = 'selectedRoles'
                        invalidFields = { invalidFields }
                    >
                        <h2>Käyttäjäryhmät</h2>
                        { roles &&
                            roles.map(role => {
                                return (
                                    <RoleCheckbox 
                                        key = { role.id }
                                        item = { role }
                                        action = { () =>  handleSelectRoles(role) }
                                        checked = {
                                            selectedRoles && selectedRoles.filter(i => i.id === role.id).length > 0 ?
                                                true 
                                            : null
                                        }
                                    />
                                )
                            })
                        }
                    </Wrapper>
                </Sidebar>
            </div>
        </Layout>
    )
}

export default EditHouseTabs