import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import App from './app';
import { AuthContextProvider } from './stores/authContext';
import { GuideContextProvider } from './stores/guideContext';
import { ProductContextProvider } from './stores/productContext';

const app = (
    <AuthContextProvider>
        <GuideContextProvider>
            <ProductContextProvider>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </ProductContextProvider>
        </GuideContextProvider>
    </AuthContextProvider>
)

if (document.getElementById('root')) {
    ReactDOM.render(app, document.getElementById('root'));
}
