import React from 'react';

import styles from './filteredItems.module.scss';

const FilteredItems = (props) => (
    <div className={ styles.filteredItemsWrapper }>
        <div className={ styles.filteredItems }>
            { props.items.map(item => {
                return (
                    <div className={ styles.itemWrapper } key={ item.id }>
                        <button onClick={ () => props.action(item.name)}>{ item.name }</button>
                    </div>
                )
            })}
        </div>
    </div>
)

export default FilteredItems