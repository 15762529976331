import React from "react";

import './textarea.scss';

const Textarea = props => (
    <div className='TextArea'>
        <strong><label>
            { props.title }
        </label></strong>
        <textarea
            className = { props.invalidFields && props.invalidFields.includes(props.name) ? 'TextAreaField Invalid' : 'TextAreaField' }
            name = { props.name }
            rows = { props.rows }
            value = { props.value }
            onChange = { props.handlechange }
            placeholder = { props.placeholder }
        />
    </div>
)

export default Textarea