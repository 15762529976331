import React from 'react';

import './mainContent.scss';

const MainContent = (props) => (
    <div className={ props.classes ? 'MainContentWrapper ' + props.classes : 'MainContentWrapper' }>
        <div className='MainContent'>
            { props.children }
        </div>
    </div>
)

export default MainContent