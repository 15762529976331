const HouseTabValidation = (name) => {
    const validation = ({
        name: {
            condition: name,
            message: 'Otsikko on pakollinen kenttä'
        }
    })

    return validation
}

export default HouseTabValidation