import { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import Layout from '../../components/UI/layout/layout';
import AuthContext from '../../stores/authContext';
import Table from '../../components/UI/table/table';

function TextsIndex(props) {
    const { user } = useContext(AuthContext)
    const [pages, setPages] = useState([])

    useEffect(() => {
        if (user && user.token) {
            axios.get(`${process.env.REACT_APP_API_PATH}/api/texts`, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                setPages(res.data)
            })
        }
    }, [user])


    return (
        <Layout>
            <h1>Tekstit</h1>
            { pages &&
                <Table>
                    <thead>
                        <tr>
                            <th>Nimi</th>
                            <th>Toiminnot</th>
                        </tr>
                    </thead>
                    <tbody>
                        { pages.map(page => {
                            return (
                                <tr key={ page.id }>
                                    <td>{ page.name }</td>
                                    <td>
                                        <NavLink to={`/tekstit/muokkaa/${page.id}`}>Muokkaa</NavLink>
                                    </td>
                                </tr>
                            )
                        }) }
                    </tbody>
                </Table>
            }
        </Layout>
    )
}

export default TextsIndex