import React, { useState } from 'react'

import Input from '../components/formElements/input/input';
import Container from '../components/UI/container/container';
import Button from '../components/formElements/button/button';
import AuthContext from '../stores/authContext';
import { useContext } from 'react';
import Message from '../components/message/message';


function Login() {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const { login, message, setMessage } = useContext(AuthContext)

    function handleFormSubmit(e) {
        e.preventDefault()
        login({email, password})
    }

    return (
        <Container>
            <form onSubmit={ (e) => handleFormSubmit(e) }>
                <h1>Kirjaudu sisään</h1>
                <Input 
                    inputtype = 'text'
                    name = 'email'
                    title = 'Sähköposti'
                    value = { email }
                    placeholder = 'Sähköposti'
                    handlechange = { (e) => setEmail(e.target.value) }
                />
                <Input 
                    inputtype = 'password'
                    name = 'password'
                    title = 'Salasana'
                    value = { password }
                    placeholder = 'Salasana'
                    handlechange = { (e) => setPassword(e.target.value)}
                />
                <Button 
                    action = { (e) => handleFormSubmit(e) }
                    type = 'btn btn-primary'
                    title = 'Kirjaudu sisään'
                />
                { message &&
                    <Message 
                        classes = { message.class }
                        message = { message.message }
                        closeMessage = { () => setMessage(null) }
                    />
                }
            </form>
        </Container>
    )
}

export default Login