const SymptomValidation = (name, machineName, selectedCategories) => {
    const validation = ({
        name: {
            condition: name,
            message: 'Otsikko on pakollinen kenttä'
        },
        machineName: {
            condition: machineName,
            message: 'Koneluettava otsikko on pakollinen kenttä'
        },
        selectedCategories: {
            condition: selectedCategories && selectedCategories.length > 0,
            message: 'Valitse oireen kategoriat'
        }
    })

    return validation
}

export default SymptomValidation