const OrderValidation = (selectedStatus) => {
    const validation = ({
        selectedStatus: {
            condition: selectedStatus,
            message: 'Valitse tilauksen tila'
        }
    })

    return validation
}

export default OrderValidation