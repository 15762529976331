import { useContext, useState, useEffect, useCallback } from 'react';
import axios from 'axios';

import Layout from '../../components/UI/layout/layout';
import AuthContext from '../../stores/authContext';
import Input from '../../components/formElements/input/input';
import Button from '../../components/formElements/button/button';
import Message from '../../components/message/message';
import Wrapper from '../../components/UI/wrapper/wrapper';
import CategoryCheckbox from '../../components/formElements/checkbox/categoryCheckbox';
import SymptomValidation from '../../validation/symptomValidation';

function EditSymptoms(props) {
    const { message, setMessage, user } = useContext(AuthContext)
    const [name, setName] = useState('')
    const [machineName, setMachineName] = useState('')
    const [categories, setCategories] = useState('')
    const [selectedCategories, setSelectedCategories] = useState([])
    const [specialChars] = useState(/[` ~!@#$%^&*()_|+\-=?;:'",.<>{}[\]\\/]/gi)
    const [invalidFields, setInvalidFields] = useState([])

    const getCategories = useCallback(() => {
        if (user && user.token) {
            axios.get(`${process.env.REACT_APP_API_PATH}/api/admin/categories`, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                const sortedByParent = res.data.sort((a, b) => a.parent_category > b.parent_category ? 1 : -1)
                const sortedCategories = []
                sortedByParent.map(category => {
                    if (!category.parent_category) {
                        category.level = 1
                        return sortedCategories.push(category)
                    } else {
                        category.level = 2   
                        return sortedCategories.splice(sortedCategories.findIndex(i => i.id === category.parent_category), 0, category)      
                    }
                })
                setCategories(sortedCategories.reverse())
            })
    
            axios.get(`${process.env.REACT_APP_API_PATH}/api/symptoms/${props.match.params.id}`, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                setName(res.data.name)
                setMachineName(res.data.machine_name)
            })
    
            axios.get(`${process.env.REACT_APP_API_PATH}/api/symptoms/symptom/categories/${props.match.params.id}`, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                setSelectedCategories(res.data)
            })
        }
    }, [user, props.match.params.id])

    useEffect(() => {
        getCategories()
    }, [getCategories])

    function clearState() {
        setName('')
        setMachineName('')
        setSelectedCategories([])
        setInvalidFields([])
    }

    function handleSelectCategories(category) {
        if (selectedCategories && selectedCategories.filter(i => i.name === category.name).length > 0) {
            const index = selectedCategories.findIndex(i => i.name === category.name)
            setSelectedCategories(prevState => [...prevState.slice(0, index), ...prevState.slice(index + 1)])
        } else {
            setSelectedCategories(prevState => [...prevState, category])
        }
    }

    function handleFormValidation(e) {
        e.preventDefault()
        const validation = SymptomValidation(name, machineName, selectedCategories)
        let message = []
        let invalid = []
        Object.entries(validation).forEach(([key, value]) => {
            if (!value.condition) {
                message.push(<li key={ key }>{ value.message }</li>)
                invalid.push(key)
            }
        })

        if (message.length > 0) {
            setInvalidFields(invalid)
            setMessage({ message: message, class: 'Error' })
            window.scrollTo({ top: 0, behavior: "smooth" })
        } else {
            handleFormSubmit()
        }
    }

    function handleFormSubmit() {
        const data = {
            name: name,
            machine_name: machineName,
            symptom_parent_categories: selectedCategories,
            position: 0
        }

        axios.patch(`${process.env.REACT_APP_API_PATH}/api/symptoms/${props.match.params.id}`, data, {
            headers: {
                'auth-token': user.token
            }
        }).then(() => {
            getCategories()
            clearState()
            setMessage({ message: 'Oireen muokkaaminen onnistui.', class: 'Success' })
            window.scrollTo({ top: 0, behavior: "smooth" })
        }).catch(() => {
            setMessage({ message: 'Jotain meni pieleen.', class: 'Error' })
            window.scrollTo({ top: 0, behavior: "smooth" })
        })
    }

    return (
        <Layout>
            { message &&
                <Message 
                    classes = { message.class }
                    message = { message.message }
                    closeMessage = { () => setMessage(null) }
                />
            }
            <h1>Muokkaa oiretta</h1>
            <form onSubmit={ handleFormValidation }>
                <Input 
                    inputtype = 'text'
                    name = 'name'
                    title = 'Nimi'
                    value = { name }
                    placeholder = 'Nimi'
                    handlechange = { (e) => setName(e.target.value) }
                    invalidFields = { invalidFields }
                />
                <Input 
                    inputtype = 'text'
                    name = 'machineName'
                    title = 'Koneluettava nimi'
                    value = { machineName }
                    placeholder = 'Nimi'
                    handlechange = { 
                        (e) => setMachineName(e.target.value.replace(specialChars, '_').replace(/[äå]/gi, 'a').replace(/[ö]/gi, 'o').toLocaleLowerCase()) 
                    }
                    invalidFields = { invalidFields }
                />
                { categories &&
                    <Wrapper>
                        <strong><label className='WrapperLabel'>Pääkategoriat</label></strong>
                        { categories.map(category => {
                            return (
                                <CategoryCheckbox 
                                    key = { category.id }
                                    category = { category }
                                    action = { () =>  handleSelectCategories(category) }
                                    selected = { selectedCategories }
                                    checked = {
                                        selectedCategories.filter(i => i.id === category.id).length > 0 ?
                                            true 
                                        : null
                                    }
                                />
                            )
                        }) }
                    </Wrapper>
                }
                <Button 
                    action = { handleFormValidation }
                    type = 'btn btn-primary'
                    title = 'Tallenna'
                />
            </form>
        </Layout>
    )
}

export default EditSymptoms