const PolicyValidation = (name, machineName, weight, description, selectedCategories) => {
    const validation = ({
        name: {
            condition: name,
            message: 'Otsikko on pakollinen kenttä'
        },
        machineName: {
            condition: machineName,
            message: 'Koneluettava otsikko on pakollinen kenttä'
        },
        weight: {
            condition: weight || weight === 0,
            message: 'Paino on pakollinen kenttä'
        },
        description: {
            condition: description,
            message: 'Lyhyt kuvaus on pakollinen kenttä'
        },
        selectedCategories: {
            condition: selectedCategories && selectedCategories.length > 0,
            message: 'Valitse ohjeen kategoriat'
        }
    })

    return validation
}

export default PolicyValidation