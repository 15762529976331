import Header from '../../header/header';
import Container from '../container/container';
import './layout.scss';

const Layout = (props) => (
    <div className='Layout'>
        <Header />
        <Container classes={ props.classes }>
            { props.children }
        </Container>
    </div>
)

export default Layout