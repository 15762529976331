import { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { NavLink } from 'react-router-dom';

import Button from '../../components/formElements/button/button';
import Message from '../../components/message/message';
import Layout from '../../components/UI/layout/layout';
import AuthContext from '../../stores/authContext';
import Table from '../../components/UI/table/table';
import Pager from '../../components/pager/pager';
import HouseSelect from '../../components/formElements/select/houseSelect';

function HouseImagesIndex(props) {
    const { message, setMessage, user } = useContext(AuthContext)
    const [images, setImages] = useState([])
    const [filteredImages, setFilteredImages] = useState([])
    const [houses, setHouses] = useState([])
    const [selectedHouse, setSelectedHouse] = useState('')
    const [firstItem, setFirstItem] = useState(0)
    const [itemsToShow] = useState(30)

    useEffect(() => {
        if (user && user.token) {
            axios.get(`${process.env.REACT_APP_API_PATH}/api/house-images`, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                setImages(res.data)
                setFilteredImages(res.data)
            })

            axios.get(`${process.env.REACT_APP_API_PATH}/api/houses`).then(res => {
                setHouses(res.data)
            })
        }
    }, [user])

    useEffect(() => {
        let filtered = []
        images.map(image => {
            if (!selectedHouse || selectedHouse === image.house_id) {
                return filtered.push(image)
            } else {
                return null
            }
        })
        setFilteredImages(filtered)
    }, [selectedHouse, images, houses])

    return (
        <Layout>
            { message &&
                <Message 
                    classes = { message.class }
                    message = { message.message }
                    closeMessage = { () => setMessage(null) }
                />
            }
            <h1>Kuvat</h1>
            <Button 
                action = { () => props.history.push('/kiinteistot/kuvat/lisaa') }
                type = 'btn btn-primary'
                title = 'Lisää uusi'
            />
            <div style={{ float: 'right', padding: '10px' }}>
                <HouseSelect 
                    name = 'selectedHouse'
                    value = { selectedHouse }
                    options = { houses }
                    handlechange = { (e) => setSelectedHouse(parseInt(e.target.children[e.target.selectedIndex].value)) }
                    placeholder = { 'Kiinteistö' }
                />
            </div>
            { images && images.length > 0 &&
                <Table>
                    <thead>
                        <tr>
                            <th>Kuva</th>
                            <th>Kiinteistö</th>
                            <th>Toiminnot</th>
                        </tr>
                    </thead>
                    <tbody className='CategoryList'>
                        { filteredImages.slice(0).splice(firstItem, itemsToShow).map(image => {
                            return (
                                <tr key={ image.id }>
                                    <td>
                                        { image.path }
                                    </td>
                                    <td>
                                        { houses.filter(i => i.house_id === image.house_id).length > 0 && houses.filter(i => i.house_id === image.house_id)[0].nimi }
                                    </td>
                                    <td>
                                        <NavLink to={`/kiinteistot/kuvat/muokkaa/${image.id}`}>
                                            Muokkaa
                                        </NavLink>
                                        <NavLink to={`/kiinteistot/kuvat/poista/${image.id}`}>
                                            Poista
                                        </NavLink>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            }
            <Pager 
                firstItem = { firstItem }
                itemsToShow = { itemsToShow }
                count = { filteredImages.length }
                filter = { selectedHouse }
                handleSetFirstItem = { (value) => setFirstItem(value) }
            />
        </Layout>
    )
}

export default HouseImagesIndex