import React, { useContext, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './optionBlock.scss';
import GuideContext from '../../../stores/guideContext';
import Input from '../../formElements/input/input';
import Button from '../../formElements/button/button';
import TextBlock from '../textBlock/textBlock';
import ImageBlock from '../imageBlock/imageBlock';
import FileBlock from '../fileBlock/fileBlock';
import VideoBlock from '../videoBlock/videoBlock';
import ConditionalBlock from '../conditionalBlock/conditionalBlock';
import BlockActions from '../components/blockActions/blockActions';
import Select from '../../formElements/select/select';
import LinkBlock from '../linkBlock/linkBlock';
import SuccessBlock from '../successBlock/successBlock';
import ReportLinkBlock from '../reportLinkBlock/reportLinkBlock';

function OptionBlock(props) {
    const { pageBlocks, pageContent, setPageContent, handleSetPageBlocks, getNextKey, getNextId, dragOver, dragEnter, dragLeave, drop, removeBlock } = useContext(GuideContext)
    const [option, setOption] = useState('')
    const [edit, setEdit] = useState(false)
    const [color, setColor] = useState(3)
    const [colors] = useState([
        { id: 1, color: '#50BE4B', name: 'Vihreä' },
        { id: 2, color: '#FF5A43', name: 'Punainen' },
        { id: 3, color: '#000000', name: 'Musta' }
    ])
    const [count, setCount] = useState('')
    const [parentName, setParentName] = useState('')
    const [showBlocks, setShowBlocks] = useState(true)

    useEffect(() => {
        if (props.initialValue) {
            setOption(props.initialValue.option)
            setColor(colors[colors.findIndex(i => i.name === props.initialValue.color)].id)
        }
    }, [props.initialValue, colors])

    useEffect(() => {
        const index = pageContent.filter(i => i.type === 'option-block' && i.parent === props.parent).findIndex(i => i.id === props.id)
        !count && setCount(index + 1)
        
        const parentTypes = [
            { name: 'conditional-block', translation: 'Ehdollinen' },
            { name: 'file-block', translation: 'Tiedosto' },
            { name: 'image-block', translation: 'Kuva' },
            { name: 'option-block', translation: 'Vaihtoehto' },
            { name: 'text-block', translation: 'Tekstialue' },
            { name: 'video-block', translation: 'Video' }
        ]

        const parentType = pageContent.filter(i => i.id === props.parent)[0].type
        const parentIndex = pageContent.filter(i => i.type === parentType).findIndex(i => i.id === props.parent)
        setParentName(parentTypes[parentTypes.findIndex(i => i.name === parentType)].translation + ' ' + parseInt(parentIndex + 1))
    }, [pageContent, props.id, props.parent, count])

    useEffect(() => {
        props.showEdit && setEdit(true)
    }, [props.showEdit])

    useEffect(() => {
        setPageContent(
            prevState => [
                ...prevState.slice(0, prevState.findIndex(i => i.id === props.id)), 
                Object.assign({}, prevState[prevState.findIndex(i => i.id === props.id)], { value: `{"option":"${option}", "color":"${colors[colors.findIndex(i => i.id === parseInt(color))].name }"}`, type: 'option-block', parent: props.parent }), 
                ...prevState.slice(prevState.findIndex(i => i.id === props.id) + 1)
            ]
        )
    }, [color, option, colors, setPageContent, props.id, props.parent])

    const handleChange = (e) => {
        setOption(e.target.value)
        setPageContent(
            prevState => [
                ...prevState.slice(0, prevState.findIndex(i => i.id === props.id)), 
                Object.assign({}, prevState[prevState.findIndex(i => i.id === props.id)], { value: `{"option":"${e.target.value}", "color":"${colors[colors.findIndex(i => i.id === parseInt(color))].name }"}`, type: 'option-block', parent: props.parent }), 
                ...prevState.slice(prevState.findIndex(i => i.id === props.id) + 1)
            ]
        )
    }

    const handleAddBlocks = (block, type) => {
        setEdit(false)
        handleSetPageBlocks(block, type)
    }
 
    return (
        <div 
            className = { 'OptionBlock Level-' + props.level }
            id = { props.id }
            onDragOver = { (e) => dragOver(e) }
            onDrop = { () => drop() }
            onDragEnter = { (e) => dragEnter(e) }
            onDragLeave = { (e) => dragLeave(e) }
        >
            <BlockActions 
                id = { props.id } 
                parent = { props.parent } 
                edit = { true } 
                editAction = { () => setEdit(true) }
                name = { 
                    <React.Fragment>
                        <span className={ 'Header-Level-' + props.level }>{ parentName }</span>
                        <FontAwesomeIcon icon='chevron-right' />
                        <span style={{ color: colors[colors.findIndex(i => i.id === parseInt(color))].color }}>{ ' Vaihtoehto ' + count }</span>
                    </React.Fragment> 
                }
                showChevron = { true }
                showBlocks = { showBlocks }
                handleSetShowBlocks = { () => setShowBlocks(!showBlocks) }
            />
            <div className={ !showBlocks ? 'Blocks Hidden' : 'Blocks'}>
                <button style={{ marginBottom: '15px' }} className={ 'OptionButton ' + colors[colors.findIndex(i => i.id === parseInt(color))].name } onClick={ () => setEdit(true) }>{ option }</button>
            </div>
            { edit &&
                <React.Fragment>
                    <div onClick={ () => setEdit(false) } className='Backdrop'></div>
                    <div className='Popup'>
                        <button className='Close' onClick={ () => setEdit(false) }>Sulje</button>
                        <Input 
                            inputtype = 'text'
                            name = 'option'
                            title = 'Vaihtoehto'
                            value = { option }
                            placeholder = 'Vaihtoehto'
                            handlechange = { handleChange }
                        />
                        <Select 
                            title = 'Väri'
                            name = 'color'
                            value = { color }
                            options = { colors }
                            handlechange = { (e) => setColor(e.target.children[e.target.selectedIndex].value) }
                            placeholder = { 'Valitse väri' }
                        />
                        <div style={{ padding: '15px', border: 'solid #000 2px', marginBottom: '30px', borderRadius: '5px' }}>
                            <h2>Lisää sisältölohkoja</h2>
                            <Button 
                                action = { () => handleAddBlocks(
                                    <TextBlock 
                                        key = { getNextKey() } 
                                        id = { getNextId() }
                                        parent = { props.id }
                                    />, 'text-block') 
                                }
                                type = 'btn btn-primary'
                                title = 'Tekstialue'
                            />
                            <Button 
                                action = { () => handleAddBlocks(
                                    <ImageBlock
                                        key = { getNextKey() } 
                                        id = { getNextId() }
                                        parent = { props.id }
                                    />, 'image-block') 
                                }
                                type = 'btn btn-primary'
                                title = 'Kuva'
                            />
                            <Button 
                                action = { () => handleAddBlocks(
                                    <FileBlock
                                        key = { getNextKey() } 
                                        id = { getNextId() }
                                        parent = { props.id }
                                        showEdit = { true }
                                    />, 'file-block') 
                                }
                                type = 'btn btn-primary'
                                title = 'Tiedosto'
                            />
                            <Button 
                                action = { () => handleAddBlocks(
                                    <VideoBlock
                                        key = { getNextKey() } 
                                        id = { getNextId() }
                                        parent = { props.id }
                                        showEdit = { true }
                                    />, 'video-block') 
                                }
                                type = 'btn btn-primary'
                                title = 'Video'
                            />
                            <Button 
                                action = { () => handleAddBlocks(
                                    <ConditionalBlock
                                        key = { getNextKey() } 
                                        id = { getNextId() }
                                        parent = { props.id }
                                        showEdit = { true }
                                        level = { props.level + 1 }
                                    />, 'conditional-block') 
                                }
                                type = 'btn btn-primary'
                                title = 'Ehdollinen'
                            />
                            <Button 
                                action = { () => handleAddBlocks(
                                    <LinkBlock
                                        key = { getNextKey() } 
                                        id = { getNextId() }
                                        parent = { props.id }
                                        showEdit = { true }
                                        level = { props.level + 1 }
                                    />, 'link-block') 
                                }
                                type = 'btn btn-primary'
                                title = 'Linkki'
                            />
                            <Button 
                                action = { () => handleAddBlocks(
                                    <ReportLinkBlock
                                        key = { getNextKey() } 
                                        id = { getNextId() }
                                        parent = { props.id }
                                        showEdit = { true }
                                        level = { props.level + 1 }
                                    />, 'report-link-block') 
                                }
                                type = 'btn btn-primary'
                                title = 'Vikailmoitus'
                            />
                            <Button 
                                action = { () => handleAddBlocks(
                                    <SuccessBlock
                                        key = { getNextKey() } 
                                        id = { getNextId() }
                                        parent = { props.id }
                                        showEdit = { true }
                                        level = { props.level + 1 }
                                    />, 'success-block') 
                                }
                                type = 'btn btn-primary'
                                title = 'Onnistuminen'
                            />
                        </div>
                        <button className='btn btn-primary' onClick={ () => setEdit(false) }>Lisää</button>
                        <button className='btn btn-danger' onClick={ () => removeBlock(props.id) }>Poista</button>
                    </div>
                </React.Fragment>
            }
            { pageBlocks && 
                <div className={ !showBlocks ? 'Blocks Hidden' : 'Blocks'}>
                    { pageBlocks.filter(i => i.props.parent === props.id) }
                </div>
            }
        </div>
    )
}

export default OptionBlock