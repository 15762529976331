const ProductCategoryValidation = (name, machineName) => {
    const validation = ({
        name: {
            condition: name,
            message: 'Otsikko on pakollinen kenttä'
        },
        machineName: {
            condition: machineName,
            message: 'Koneluettava otsikko on pakollinen kenttä'
        }
    })

    return validation
}

export default ProductCategoryValidation