import React from 'react';

import './select.scss';

const Select = props => {
	return (
		<div className='Select'>
			{ props.title ? 
				<strong><label htmlFor={ props.name }>
					{ props.title }
					{ props.required ? <span className="Required">*</span> : null }
				</label></strong>
			: null }
			<select
				className = { props.invalidFields && props.invalidFields.includes(props.name) ? 'SelectField Invalid' : 'SelectField' }
				id = { props.name }
				name = { props.name }
				value = {props.value}
				onChange = { props.handlechange }
			>
				{ props.placeholder ? 
				<option value='0'>
					{props.placeholder}
				</option>
				: null}
				{ props.options.map(option => {
					return (
						<option key={ option.id } name={ option.name } value={ option.id } label={ option.level && option.level > 1 ? '-' + option.name : option.name }>
							{ option.name }
						</option>
					)
				}) }
			</select>
		</div>
	);
};

export default Select