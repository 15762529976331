import React from 'react';

import './checkbox.scss';

const Checkbox = (props) => (
    <div className='Checkbox'>
        <input 
            type = 'checkbox' 
            id = { props.id } 
            value = { props.value ? props.value : props.id } 
            name = { props.name } 
            checked = { props.checked ? props.checked : '' } 
            onChange = { props.action } 
        />
        <label htmlFor={ props.id }>{ props.name }</label>
    </div>
)

export default Checkbox