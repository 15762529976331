import { createContext, useState, useEffect } from "react";
import cookieCutter from 'cookie-cutter';

const AuthContext = createContext({
    user: null,
    message: null,
    login: () => {},
    logout: () => {},
    authReady: false
})

export const AuthContextProvider = ({ children }) => {
    const [user, setUser] = useState(null)
    const [message, setMessage] = useState(null)
    const [authReady, setAuthReady] = useState(false)

    useEffect(() => {
        const token = cookieCutter.get('token')
        if (!token) {
            setAuthReady(true)
        } else {
            fetch(`${process.env.REACT_APP_API_PATH}/api/users/token/${token}`, {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                }
            })
            .then(response => response.json())
            .then(data => {
              setUser(data)
              setAuthReady(true)
            })
            .catch(() => {
                setAuthReady(true)
            })
        }
    }, [])

    useEffect(() => {
        if (!user && authReady) {
            cookieCutter.set('token', '', { path: '/', expires: new Date(0) })
        } else if (authReady) {
            cookieCutter.set('token', user.token, { path: '/', expires: new Date(new Date().getTime() + (14*24*60*60*1000)) })
        }
    }, [user, authReady])

    const login = loginData => {
        const data = {
            email: loginData.email,
            password: loginData.password
        }

        fetch(`${process.env.REACT_APP_API_PATH}/api/users/login`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then(response => response.json())
        .then(data => {
          setUser(data)
          setMessage(null)
        })
        .catch(() => {
          setMessage({ message: 'Kirjautuminen epäonnistui.', class: 'Error' })
        });
    }

    const logout = () => {
        setUser(null)
    }

    const context = { user, login, message, setMessage, logout, authReady }

    return (
        <AuthContext.Provider value={context}>
            { children }
        </AuthContext.Provider>
    )
}

export default AuthContext