import React from 'react';

import './modal.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Modal = (props) => (
    <div className='Modal'>
        <div className='CloseModal'>
            <FontAwesomeIcon icon='times' onClick={ () => props.close() } />
        </div>
        { props.children }
    </div>
)

export default Modal