import React from 'react';

import './select.scss';

const HouseSelect = props => {
	return (
		<div className='Select'>
			{ props.title ? 
				<strong><label htmlFor={ props.name }>
					{ props.title }
					{ props.required ? <span className="Required">*</span> : null }
				</label></strong>
			: null }
            { props.validationTarget && !props.validationTarget.valid && props.validationTarget.touched ?
				<div className='InputErrorMessage'>
					<p>{ props.validationTarget.message }</p>
				</div>
			: null }
			<select
				className = { props.message ? 'SelectField Error' : 'SelectField' }
				id = { props.name }
				name = { props.name }
				value = {props.value}
				onChange = { props.handlechange }
			>
				{ props.placeholder ? 
				<option value='0'>
					{props.placeholder}
				</option>
				: null}
				{ props.options.map(option => {
					return (
						<option key={ option.house_id } name={ option.nimi } value={ option.house_id } label={ option.nimi }>
							{ option.nimi }
						</option>
					)
				}) }
			</select>
		</div>
	);
};

export default HouseSelect