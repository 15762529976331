import { useContext, useState, useEffect } from 'react';
import axios from 'axios';

import Layout from '../../components/UI/layout/layout';
import AuthContext from '../../stores/authContext';
import Input from '../../components/formElements/input/input';
import Button from '../../components/formElements/button/button';
import Message from '../../components/message/message';
import RoleValidation from '../../validation/roleValidation';
import MainContent from '../../components/UI/mainContent/mainContent';
import Wrapper from '../../components/UI/wrapper/wrapper';
import Checkbox from '../../components/formElements/checkbox/checkbox';

function CreateRoles() {
    const { message, setMessage, user } = useContext(AuthContext)
    const [name, setName] = useState('')
    const [permissions, setPermissions] = useState([])
    const [selectedPermissions, setSelectedPermissions] = useState([])
    const [invalidFields, setInvalidFields] = useState([])

    useEffect(() => {
        if (user && user.token) {
            axios.get(`${process.env.REACT_APP_API_PATH}/api/permissions`, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                setPermissions(res.data)
            })
        }
    }, [user])

    function clearState() {
        setName('')
        setSelectedPermissions([])
        setInvalidFields([])
    }

    function handleSelectPermissions(permission) {
        if (selectedPermissions && selectedPermissions.filter(i => i.name === permission.name).length > 0) {
            const index = selectedPermissions.findIndex(i => i.name === permission.name)
            setSelectedPermissions(prevState => [...prevState.slice(0, index), ...prevState.slice(index + 1)])
        } else {
            setSelectedPermissions(prevState => [...prevState, permission])
        }
    }

    function handleFormValidation(e) {
        e.preventDefault()
        const validation = RoleValidation(name)
        let message = []
        let invalid = []
        Object.entries(validation).forEach(([key, value]) => {
            if (!value.condition) {
                message.push(<li key={ key }>{ value.message }</li>)
                invalid.push(key)
            }
        })

        if (message.length > 0) {
            setInvalidFields(invalid)
            setMessage({ message: message, class: 'Error' })
            window.scrollTo({ top: 0, behavior: "smooth" })
        } else {
            handleFormSubmit()
        }
    }

    function handleFormSubmit() {
        const data = {
            name: name,
            role_permissions: selectedPermissions
        }

        axios.post(`${process.env.REACT_APP_API_PATH}/api/roles`, data, {
            headers: {
                'auth-token': user.token
            }
        }).then(() => {
            clearState()
            setMessage({ message: 'Käyttäjäryhmän lisääminen onnistui.', class: 'Success' })
            window.scrollTo({ top: 0, behavior: "smooth" })
        }).catch(() => {
            setMessage({ message: 'Jotain meni pieleen.', class: 'Error' })
            window.scrollTo({ top: 0, behavior: "smooth" })
        })
    }

    return (
        <Layout>
            <MainContent>
                { message &&
                    <Message 
                        classes = { message.class }
                        message = { message.message }
                        closeMessage = { () => setMessage(null) }
                    />
                }
                <h1>Lisää käyttäjäryhmä</h1>
                <form onSubmit={ handleFormValidation }>
                    <Input 
                        inputtype = 'text'
                        name = 'name'
                        title = 'Käyttäjäryhmä'
                        value = { name }
                        placeholder = 'Käyttäjäryhmä'
                        handlechange = { (e) => setName(e.target.value) }
                        invalidFields = { invalidFields }
                    />
                    <Wrapper 
                        name = 'selectedPermissions'
                        invalidFields = { invalidFields }
                    >
                        <h2>Käyttöoikeudet</h2>
                        { permissions &&
                            permissions.map(permission => {
                                return (
                                    <Checkbox 
                                        key = { permission.id }
                                        id = { permission.id }
                                        name = { permission.name }
                                        action = { () =>  handleSelectPermissions(permission) }
                                        selected = { selectedPermissions }
                                        checked = {
                                            selectedPermissions.filter(i => i.id === permission.id).length > 0 ?
                                                true 
                                            : null
                                        }
                                    />
                                )
                            }) 
                        }
                    </Wrapper>
                    <Button 
                        action = { handleFormValidation }
                        type = 'btn btn-primary'
                        title = 'Tallenna'
                    />
                </form>
            </MainContent>
        </Layout>
    )
}

export default CreateRoles