import { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { NavLink } from 'react-router-dom';

import Button from '../../components/formElements/button/button';
import Message from '../../components/message/message';
import Layout from '../../components/UI/layout/layout';
import AuthContext from '../../stores/authContext';
import Table from '../../components/UI/table/table';
import Select from '../../components/formElements/select/select';
import Pager from '../../components/pager/pager';

function SymptomsIndex(props) {
    const { message, setMessage, user } = useContext(AuthContext)
    const [symptoms, setSymptoms] = useState([])
    const [filteredSymptoms, setFilteredSymptoms] = useState([])
    const [categories, setCategories] = useState('')
    const [symptomCategories, setSymptomcategories] = useState('')
    const [selectedCategory, setSelectedCategory] = useState('')
    const [filter, setFilter] = useState('')
    const [firstItem, setFirstItem] = useState(0)
    const [itemsToShow] = useState(20)

    useEffect(() => {
        if (user && user.token) {
            axios.get(`${process.env.REACT_APP_API_PATH}/api/symptoms`, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                setSymptoms(res.data)
                setFilteredSymptoms(res.data)
            })
    
            axios.get(`${process.env.REACT_APP_API_PATH}/api/categories`).then(res => {
                setCategories(res.data)
            })
    
            axios.get(`${process.env.REACT_APP_API_PATH}/api/symptoms/symptom/categories`, {
                headers: {
                    'auth-token': user.token
                }
            }).then(res => {
                setSymptomcategories(res.data)
            })
        }
    }, [user])

    useEffect(() => {
        let filtered = []
        symptoms.map(symptom => {
            if (((!filter || symptom.name.toLowerCase().includes(filter.toLowerCase())) && (!selectedCategory || symptomCategories.filter(i => i.id === symptom.id && i.category_id === selectedCategory).length > 0))) {
                return filtered.push(symptom)
            } else {
                return null
            }
        })
        setFilteredSymptoms(filtered)
    }, [filter, selectedCategory, symptomCategories, symptoms])

    useEffect(() => {
        setFirstItem(0)
    }, [filter, selectedCategory])

    return (
        <Layout>
            { message &&
                <Message 
                    classes = { message.class }
                    message = { message.message }
                    closeMessage = { () => setMessage(null) }
                />
            }
            <h1>Oireet</h1>
            <Button 
                action = { () => props.history.push('/ohjeet/oireet/lisaa') }
                type = 'btn btn-primary'
                title = 'Lisää uusi'
            />
            { categories &&
                <div className='GuideFilters'>
                    <Select 
                        name = 'selectedCategory'
                        value = { selectedCategory }
                        options = { categories }
                        handlechange = { (e) => setSelectedCategory(parseInt(e.target.children[e.target.selectedIndex].value)) }
                        placeholder = { 'Kategoria' }
                    />
                    <input style={{ float: 'right', padding: '10px', marginTop: '15px' }} value={ filter } placeholder='Hae...' onChange={ (e) => setFilter(e.target.value) } />
                </div>
            }
            { symptoms && symptomCategories &&
                <Table>
                    <thead>
                        <tr>
                            <th>Nimi</th>
                            <th>Pääkategoriat</th>
                            <th>Lisätty</th>
                            <th>Päivitetty</th>
                            <th>Toiminnot</th>
                        </tr>
                    </thead>
                    <tbody className='CategoryList'>
                        { filteredSymptoms.slice(0).splice(firstItem, itemsToShow).map(symptom => {
                            return (
                                <tr key={ symptom.id }>
                                    <td>{ symptom.name }</td>
                                    <td>
                                        <ul style={{ margin: '0', padding: '0' }}>
                                            { symptomCategories.filter(i => i.id === symptom.id).map(category => { return <li key={ category.name }>{ category.name }</li> }) }
                                        </ul>
                                    </td>
                                    <td>{ `${new Date(symptom.created_at).getDate()}.${new Date(symptom.created_at).getMonth() + 1}.${new Date(symptom.created_at).getFullYear()}` }</td>
                                    <td>{ `${new Date(symptom.updated_at).getDate()}.${new Date(symptom.updated_at).getMonth() + 1}.${new Date(symptom.updated_at).getFullYear()}` }</td>
                                    <td><NavLink to={`/ohjeet/oireet/muokkaa/${symptom.id}`}>Muokkaa</NavLink>
                                    <NavLink to={`/ohjeet/oireet/poista/${symptom.id}`}>Poista</NavLink></td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            }
            <Pager 
                firstItem = { firstItem }
                itemsToShow = { itemsToShow }
                count = { filteredSymptoms.length }
                filter = { filter }
                filter2 = { selectedCategory }
                handleSetFirstItem = { (value) => setFirstItem(value) }
            />
        </Layout>
    )
}

export default SymptomsIndex