import { useState, useEffect } from 'react';

import './pager.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Pager(props) {
    const [activePage, setActivePage] = useState(1)
    const [pages, setPages] = useState([])

    useEffect(() => {
        const handleSelectPage = (e) => {
            setActivePage(+e.target.innerHTML)
            props.handleSetFirstItem((+e.target.innerHTML * props.itemsToShow) - props.itemsToShow)
        }

        let page = 1
        let pageArray = []
        do {
            pageArray.push(<li key={ page } className = {activePage === page ? 'Selected' : null} onClick={ (e) => handleSelectPage(e) }>{ page }</li>) 
            page += 1
		}
        while (Math.ceil(props.count/props.itemsToShow)/page >= 1)
        setPages(pageArray)
    }, [props.count, props.itemsToShow, activePage, props])

    useEffect(() => {
        setActivePage(1)
    }, [props.filter, props.filter2])

    function shownPagesHandler(pages) {
		if (activePage === 1) {
			return pages.splice(activePage - 1, 5)
		} else if (activePage === 2) {
			return pages.splice(activePage - 2, 5)
		} else if ((activePage === Math.ceil(props.count/props.itemsToShow)) && activePage > 5) {
            return pages.splice(activePage - 5, 5)
        } else if ((activePage === Math.ceil(props.count/props.itemsToShow)) && activePage > 4) {
            return pages.splice(activePage - 4, 5)
        } else if ((activePage === Math.ceil(props.count/props.itemsToShow) - 1) && activePage > 4) {
            return pages.splice(activePage - 4, 5)
        } else {
			return pages.splice(activePage - 3, 5)
		}
    }

    function handleSetFirstPage() {
        props.handleSetFirstItem(0)
        setActivePage(1)
    }

    function handleSetPreviousPage() {
        props.handleSetFirstItem(props.firstItem - props.itemsToShow)
        setActivePage(activePage - 1)
    }

    function handleSetNextPage() {
        props.handleSetFirstItem(props.firstItem + props.itemsToShow)
        setActivePage(activePage + 1)
    }

    function handleSetLastPage() {
        let first = null
        if (props.count % props.itemsToShow === 0) {
            first = props.count - props.itemsToShow
        } else {
            first = props.count - (props.count % props.itemsToShow)
        }

        props.handleSetFirstItem(first)
        setActivePage(Math.ceil(props.count/props.itemsToShow))
    }
    
    return (
        <div className='Pager'>
            { activePage > 1 &&
                <div className='PagerPrev' onClick={ () => handleSetPreviousPage() }><FontAwesomeIcon icon='chevron-left' /></div>
            }
            { activePage > 3 &&
                <div className='PagerPrev' onClick={ () => handleSetFirstPage() }>1</div>
            }
            { props.count > props.itemsToShow &&
                <div className='PageCounter'>
                    { shownPagesHandler(pages) }
                </div>
            }
            { activePage < Math.ceil(props.count/props.itemsToShow) + (activePage - 5) && activePage < Math.ceil(props.count/props.itemsToShow) - 2 &&
                <div className='PagerNext' onClick={ () => handleSetLastPage() }>{ Math.ceil(props.count/props.itemsToShow) }</div>
            }
            { props.count - (activePage * props.itemsToShow) > 0 &&
                <div className='PagerNext' onClick={ () => handleSetNextPage() }><FontAwesomeIcon icon='chevron-right' /></div>
            }
        </div>
    )
}

export default Pager