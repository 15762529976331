const HouseFilesValidation = (houseID, fileName) => {
    const validation = ({
        houseID: {
            condition: houseID,
            message: 'Valitse kiinteistö'
        },
        fileName: {
            condition: fileName,
            message: 'Valitse lisättävä tiedosto'
        }
    })

    return validation
}

export default HouseFilesValidation