import React from 'react';

import './sidebar.scss';

const Sidebar = (props) => (
    <div className='SidebarWrapper'>
        <div className='Sidebar'>
            { props.children }
        </div>
    </div>
)

export default Sidebar