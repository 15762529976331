import DashboardItem from "../components/UI/dashboardItem/dashboardItem";
import Layout from "../components/UI/layout/layout";

function Frontpage() {
    return (
        <Layout>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <DashboardItem 
                    label = 'Työohjeet'
                    link = '/ohjeet'
                />
                <DashboardItem 
                    label = 'Tuotteet'
                    link = '/tuotteet'
                />
                <DashboardItem 
                    label = 'Vikailmoitukset'
                    link = '/vikailmoitukset'
                />
                <DashboardItem 
                    label = 'Tilaukset'
                    link = '/tilaukset'
                />
                <DashboardItem 
                    label = 'Toimintatavat'
                    link = '/toimintatavat'
                />
                <DashboardItem 
                    label = 'Kiinteistöt'
                    link = '/kiinteistot'
                />
                <DashboardItem 
                    label = 'Käyttäjät'
                    link = '/kayttajat'
                />
                <DashboardItem 
                    label = 'Käyttäjäryhmät'
                    link = '/kayttajaryhmat'
                />
                <DashboardItem 
                    label = 'Asetukset'
                    link = '/asetukset'
                />
                <DashboardItem 
                    label = 'Tekstit'
                    link = '/tekstit'
                />
            </div>
        </Layout>
    )
}

export default Frontpage