import React, { useContext, useState, useEffect } from 'react';

import GuideContext from '../../../stores/guideContext';
import Textarea from '../../formElements/textarea/textarea';
import './videoBlock.scss';
import BlockActions from '../components/blockActions/blockActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Input from '../../formElements/input/input';

function VideoBlock(props) {
    const { pageContent, setPageContent, drag, dragEnd, removeBlock } = useContext(GuideContext)
    const [label, setLabel] = useState('')
    const [video, setVideo] = useState('')
    const [draggable, setDraggable] = useState(false)
    const [edit, setEdit] = useState(false)
    const [count, setCount] = useState('')

    useEffect(() => {
        setPageContent(
            prevState => [
                ...prevState.slice(0, prevState.findIndex(i => i.id === props.id)), 
                Object.assign({}, prevState[prevState.findIndex(i => i.id === props.id)], { value: `{"label":"${label}", "video":${JSON.stringify(video)}}`, type: 'video-block', parent: props.parent }), 
                ...prevState.slice(prevState.findIndex(i => i.id === props.id) + 1)
            ]
        )
    }, [label, video, props.id, props.parent, setPageContent])

    useEffect(() => {
        if (props.initialValue && !props.initialValue.video && !props.initialValue.label) {
            setVideo(props.initialValue)
        } else if (props.initialValue) {
            setVideo(props.initialValue.video)
            setLabel(props.initialValue.label)
        }
    }, [props.initialValue])

    useEffect(() => {
        const index = pageContent.filter(i => i.type === 'video-block').findIndex(i => i.id === props.id)
        !count && setCount(index + 1)
    }, [props.id, pageContent, count])

    useEffect(() => {
        props.showEdit && setEdit(true)
    }, [props.showEdit])

    return (
        <div 
            className = 'VideoBlock'
            draggable = { draggable }
            id = { props.id }
            onDrag = { (e) => drag(e) }
            onDragEnd = { () => dragEnd() }
        >
            <div>
                <BlockActions 
                    id = { props.id } 
                    parent = { props.parent } 
                    edit = { true } 
                    editAction = { () => setEdit(true) }
                    name = { 'Video ' + count }
                />
                <FontAwesomeIcon 
                    onMouseOver = { () => !draggable ? setDraggable(true) : null } 
                    onMouseLeave = { () => draggable ? setDraggable(false) : null }
                    icon = 'arrows-alt' 
                />
            </div>
            { video ?
                <>
                    { label && <h2>{ label }</h2> }
                    <div className='video' dangerouslySetInnerHTML={{ __html: video }}></div>
                </>
            : 
                <div>
                    <label htmlFor={ 'upload_' + props.id }>
                        <div className='Placeholder'>
                            <h2>Lisää video</h2>
                        </div>
                    </label>
                    <input 
                        name = 'video'
                        className = 'UploadButton' 
                        id = { 'upload_' + props.id } 
                        onClick = { () => setEdit(true) } 
                    />
                </div>
            }
            { edit &&
                <React.Fragment>
                    <div onClick={ () => setEdit(false) } className='Backdrop'></div>
                    <div className='Popup'>
                        <button className='Close' onClick={ () => setEdit(false) }>Sulje</button>
                        <Input 
                            inputtype = 'text'
                            name = 'label'
                            title = 'Otsikko'
                            value = { label }
                            placeholder = 'Otsikko'
                            handlechange = { (e) => setLabel(e.target.value) }
                        />
                        <Textarea 
                            name = 'video'
                            value = { video }
                            rows = { 5 }
                            handlechange = { (e) => setVideo(e.target.value) }
                            placeholder = 'Videon upotuskoodi'
                        />
                        <button className='btn btn-primary' onClick={ () => setEdit(false) }>Lisää</button>
                        <button className='btn btn-danger' onClick={ () => removeBlock(props.id) }>Poista</button>
                    </div>
                </React.Fragment>
            }
        </div>
    )
}

export default VideoBlock